import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ReactGA from "react-ga4"

import { ImageEditorContext } from "../../context/ImageEditor"
import { UIContext } from "../../context/UI"

import { Link, NavLink } from "react-router-dom"
import { getLandingCounters } from "../../services/landing"
import { DropdownLanguage } from "../components/DropdownLanguage"
import { TopBar } from "../navbar/TopBar"

function LandingPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    setwidth,
    setFearureWidth,
    setFeatureRatioWidth,
    selectedVisualFormatConfig,

    file,
    setFile,
    setOriginalFile,

    backgroundImages,

    landingVisual2FormatConfigs,
    setFearure2Width,

    setFileLoaded,
    setInsetColorManuallySet,
    setAlreadyAutoCropped,
  } = useContext(ImageEditorContext)

  const featuresRef = useRef<null | HTMLDivElement>(null)
  const pricingRef = useRef<null | HTMLDivElement>(null)
  const faqRef = useRef<null | HTMLDivElement>(null)
  const topRef = useRef<null | HTMLDivElement>(null)

  const { isDark, toggleDarkMode } = useContext(UIContext)

  useEffect(() => {
    document.title = "SocialScreenshots"

    if (process.env.REACT_APP_ENV === "production") {
      // posthog?.capture("$pageview")

      ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "SocialScreenshots",
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const scrollToTop = () =>
    topRef.current!.scrollIntoView({ behavior: "smooth" })
  const scrollToFeatures = () =>
    featuresRef.current!.scrollIntoView({ behavior: "smooth" })
  const scrollToPricing = () =>
    pricingRef.current!.scrollIntoView({ behavior: "smooth" })
  const scrollToFaq = () =>
    faqRef.current!.scrollIntoView({ behavior: "smooth" })

  function handleChange(e: any) {
    setFileLoaded(false)
    setFile(URL.createObjectURL(e.target.files[0]))
    setInsetColorManuallySet(false)
    setAlreadyAutoCropped(false)
    setOriginalFile(URL.createObjectURL(e.target.files[0]))
    navigate("/editor")
  }

  const screenRef = useRef<HTMLDivElement>(null)
  const featureScreenRef = useRef<HTMLDivElement>(null)
  const feature2ScreenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setwidth(entries[0].contentRect.width)
    })
    if (screenRef.current) {
      observer.observe(screenRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (screenRef.current) observer.unobserve(screenRef.current)
    }
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFearureWidth(entries[0].contentRect.width)
    })
    if (featureScreenRef.current) {
      observer.observe(featureScreenRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (featureScreenRef.current) observer.unobserve(featureScreenRef.current)
    }
  }, [])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFearure2Width(entries[0].contentRect.width)
    })
    if (feature2ScreenRef.current) {
      observer.observe(feature2ScreenRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (feature2ScreenRef.current)
        observer.unobserve(feature2ScreenRef.current)
    }
  }, [])

  const [fearureContainerWidth, setFearureContainerWidth] = useState<number>(0)
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFearureContainerWidth(entries[0].contentRect.width)
    })
    if (featureContainerRef.current) {
      observer.observe(featureContainerRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (featureContainerRef.current)
        observer.unobserve(featureContainerRef.current)
    }
  }, [])

  const [fearure2ContainerWidth, setFearure2ContainerWidth] =
    useState<number>(0)
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFearure2ContainerWidth(entries[0].contentRect.width)
    })
    if (feature2ContainerRef.current) {
      observer.observe(feature2ContainerRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (feature2ContainerRef.current)
        observer.unobserve(feature2ContainerRef.current)
    }
  }, [])

  const featureContainerRef = useRef<HTMLDivElement>(null)
  const feature2ContainerRef = useRef<HTMLDivElement>(null)

  const capture = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia()
    // get correct video track
    const track = stream.getVideoTracks()[0]
    // init Image Capture and not Video stream

    const imageCapture = new (window as any).ImageCapture(track)
    // take first frame only
    const bitmap = await imageCapture.grabFrame()
    // destory video track to prevent more recording / mem leak
    track.stop()
    window.open(process.env.REACT_APP_FRONT_URL + "/screen-success")

    const canvas = document.createElement("canvas")
    // this could be a document.createElement('canvas') if you want
    // draw weird image type to canvas so we can get a useful image
    canvas.width = bitmap.width
    canvas.height = bitmap.height
    const context = canvas.getContext("2d") as CanvasRenderingContext2D

    context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
    const image = canvas.toDataURL()
    canvas.remove()
    setFileLoaded(false)
    setInsetColorManuallySet(false)
    setAlreadyAutoCropped(false)
    setFile(image)
    setOriginalFile(image)
    navigate("/editor")
  }

  const [isDragHover, setIsDragHover] = useState<boolean>(false)

  const onDragOver = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    event.preventDefault()
    setIsDragHover(true)
  }

  const onDragEnter = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(true)
  }

  const onDragEnd = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(false)
  }

  const onFileDrop = (e: any) => {
    let event = e
    event.stopPropagation()
    event.preventDefault()

    if (event?.dataTransfer.files) {
      if (event?.dataTransfer.files) {
        setFileLoaded(false)
        setInsetColorManuallySet(false)
        setAlreadyAutoCropped(false)
        setFile(URL.createObjectURL(event?.dataTransfer.files[0]))
        setOriginalFile(URL.createObjectURL(event?.dataTransfer.files[0]))
        navigate("/editor")
      }
    }
  }

  const [isChromium, setIsChromium] = useState<boolean | null>(null)

  useEffect(() => {
    if (isChromium === null) {
      var isChromiumBased = !!(window as any).chrome
      setIsChromium(isChromiumBased)
    }
  }, [])

  let timer: any
  let timer2: any

  const [indexFeature, setIndexFeature] = useState<number>(0)
  const updateCount = () => {
    timer =
      !timer &&
      setInterval(() => {
        if (indexFeature === 0) {
          setFeatureRatioWidth(1080)
          setIndexFeature((prevCount) => prevCount + 1)
        }
        if (indexFeature === 1) {
          setFeatureRatioWidth(1920)
          setIndexFeature((prevCount) => prevCount + 1)
        }
        if (indexFeature === 2) {
          setFeatureRatioWidth(1080)
          setIndexFeature((prevCount) => prevCount + 1)
        }
      }, 3000)
  }

  useEffect(() => {
    updateCount()

    return () => clearInterval(timer)
  }, [])

  const [indexBg, setIndexBg] = useState<number>(0)

  const brandScreens = useMemo(
    () => [
      {
        bg: backgroundImages?.macos[!isDark ? 8 : 9]?.url,
        image: `/assets/${isDark ? "landing-dark" : "landing-light"}/screen-editor.png`,
        insetColor: isDark ? "#0a0f14" : "#f0f5fa",
      },
      {
        bg: backgroundImages?.macos[!isDark ? 4 : 5]?.url,
        image: `/assets/${isDark ? "landing-dark" : "landing-light"}/screen-champo.png`,
        insetColor: isDark ? "#0a0f14" : "#f0f5fa",
      },
      {
        bg: backgroundImages?.macos[7]?.url,
        image: `/assets/${isDark ? "landing-dark" : "landing-light"}/screen-agoraquiz.png`,
        insetColor: isDark ? "#191919" : "#F0F0F0",
      },
    ],
    [isDark, backgroundImages],
  )
  const updateCount2 = () => {
    timer2 =
      !timer2 &&
      setInterval(() => {
        setIndexBg((prevCount) => prevCount + 1)
      }, 3500)
  }
  useEffect(() => {
    updateCount2()

    return () => clearInterval(timer2)
  }, [])

  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null)

  const setQuestion = (index: number) => {
    if (selectedQuestion === index) {
      setSelectedQuestion(null)
    } else {
      setSelectedQuestion(index)
    }
  }

  const fakeWallOfLove = [
    {
      image: "pic-1.png",
      name: "Gab Franck",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_1"),
    },
    {
      image: "pic-2.png",
      name: "Negative Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_2"),
    },
    {
      image: "pic-3.png",
      name: "Gab Withsunglasses",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_3"),
    },
    {
      image: "pic-4.png",
      name: "Gab Withahat",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_4"),
    },
    {
      image: "pic-5.png",
      name: "Reversed Gab",
      tag: "@GabFrk",
      message: "!loot siht evol I",
    },
    {
      image: "pic-6.png",
      name: "Honest Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_6"),
    },
    {
      image: "pic-7.png",
      name: "Cropped Gab",
      tag: "@GabFrk",
      message: "I just discover this tool and I really love to be able to...",
    },
    {
      image: "pic-8.png",
      name: "Gab Thebot",
      tag: "@GabFrk",
      message: "Boop beep boop. Good tool !",
    },
    {
      image: "pic-9.png",
      name: "Withglasses Gab",
      tag: "@GabFrk",
      message: t("landingPage.fake_testimonial_9"),
    },
    {
      image: "pic-10.png",
      name: "Nomoreidea Gab",
      tag: "@GabFrk",
      message:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure hic molestias repellendus quae excepturi? Accusamus facere adipisci architecto reprehenderit nobis eveniet quaerat beatae. Aliquam laborum aut possimus sint minus temporibus!",
    },
  ]

  const getTestimonialItem = (index: number) => {
    return (
      <div className="bordered-div" style={{ marginTop: "1rem" }}>
        <div className="flex-row" style={{ marginBottom: "0.75rem" }}>
          <img
            src={"/assets/testimonial-pics/" + fakeWallOfLove[index].image}
            alt=""
            style={{
              width: "54px",
              height: "54px",
              borderRadius: 32,
              marginRight: "0.5rem",
            }}
          />

          <div className="flex-column" style={{ justifyContent: "center" }}>
            <h3
              className="font-bold"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {fakeWallOfLove[index].name}
            </h3>
            <p
              className="half-opacity"
              style={{
                fontSize: "0.8rem",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {fakeWallOfLove[index].tag}
            </p>
          </div>
        </div>
        <p>{fakeWallOfLove[index].message}</p>

        <div
          style={{
            fontSize: "0.85rem",
            marginTop: "0.5rem",
            marginBottom: "-0.25rem",
            fontStyle: "italic",
            opacity: 0.5,
            textAlign: "right",
          }}
        >
          {new Date("2024-05-08").toLocaleDateString()}
        </div>
      </div>
    )
  }

  const handlePaste = async (event: any) => {
    try {
      if (!navigator.clipboard) {
        console.error("Clipboard API not available")
        return
      }

      const clipboardItems = await navigator.clipboard.read()
      console.log(clipboardItems)

      for (const clipboardItem of clipboardItems) {
        const imageTypes = clipboardItem.types.find((type) =>
          type.startsWith("image/"),
        )

        if (imageTypes) {
          const blob = await clipboardItem.getType(imageTypes)
          const url = URL.createObjectURL(blob)
          setFileLoaded(false)
          setInsetColorManuallySet(false)
          setAlreadyAutoCropped(false)
          setFile(url)
          setOriginalFile(url)
          navigate("/editor")
          break // Assuming we need the first image
        }
      }
    } catch (err) {
      console.error("Failed to read clipboard:", err)
    }
  }

  return (
    <>
      <div
        className={"main-container"}
        style={{ height: "100%" }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragEnd}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
        onPaste={handlePaste}
      >
        {isDragHover && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "#f39c1222",
              border: "0.5rem solid #f39c1288",
              zIndex: 99999,
            }}
            className="flex-column flex-center"
          >
            <div
              className="white-div bordered-div flex-column flex-center"
              style={{ padding: "2rem" }}
            >
              <span
                className="msymbols material-symbols-outlined"
                style={{ fontSize: 75, opacity: 0.5 }}
              >
                place_item
              </span>
              <h1 className="font-bold" style={{ color: "#f39c12" }}>
                {t("landingPage.drop_screenshot_here")}
              </h1>
            </div>
          </div>
        )}
        <TopBar
          scrollToPricing={scrollToPricing}
          scrollToFeatures={scrollToFeatures}
          scrollToTop={scrollToTop}
          scrollToFaq={scrollToFaq}
        />
        <div className="main-content" style={{ paddingTop: "75px" }}>
          <div className="LandingPage">
            <div
              className="landing-container flex-row flex-center"
              ref={topRef}
              style={{ minHeight: "100%", marginBottom: "2.5rem" }}
            >
              <div className="flex-column text-hero-content flex-start">
                <h1 className={`hero-title `} style={{ marginTop: "2.5rem" }}>
                  <span>
                    <Trans>{t("landingPage.hero_title")}</Trans>
                  </span>
                </h1>

                <p className="main-paragraph">
                  <Trans>{t("landingPage.hero_label")}</Trans>
                </p>

                <a
                  href="https://www.producthunt.com/posts/socialscreenshots?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-socialscreenshots"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=458069&theme=light"
                    alt="SocialScreenshots - Create&#0032;Stunning&#0032;Visuals&#0032;from&#0032;Your&#0032;Screenshots&#0032;for&#0032;Free&#0033; | Product Hunt"
                    style={{
                      width: "250px",
                      height: "54px",
                      marginBottom: "2rem",
                    }}
                    width="250"
                    height="54"
                  />
                </a>

                <div className="main-button flex-column">
                  <div className="user-counter flex-row">
                    <img
                      className="profile-pic"
                      src="/assets/landing-people-profile/img1.jpg"
                      alt=""
                    />
                    <img
                      className="profile-pic"
                      src="/assets/landing-people-profile/img2.jpg"
                      alt=""
                    />
                    <img
                      className="profile-pic"
                      src="/assets/landing-people-profile/img3.jpg"
                      alt=""
                    />
                    <img
                      className="profile-pic"
                      src="/assets/landing-people-profile/img4.jpg"
                      alt=""
                    />
                    <img
                      className="profile-pic"
                      src="/assets/landing-people-profile/img5.jpg"
                      alt=""
                    />

                    <div
                      className="flex-column"
                      style={{ padding: "4px 0", marginLeft: "0.5rem" }}
                    >
                      <div className="flex-row">
                        <img
                          className="star-image"
                          src="/assets/star.svg"
                          alt=""
                        />
                        <img
                          className="star-image"
                          src="/assets/star.svg"
                          alt=""
                        />
                        <img
                          className="star-image"
                          src="/assets/star.svg"
                          alt=""
                        />
                        <img
                          className="star-image"
                          src="/assets/star.svg"
                          alt=""
                        />
                        <img
                          className="star-image"
                          src="/assets/star.svg"
                          alt=""
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="main-hero-container flex-column full-width flex-center"
                style={{
                  maxWidth: 1000,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <div className="full-width">
                  <div
                    className="flex-row flex-center flex-1"
                    style={{
                      borderRadius: 16,

                      overflow: "hidden",
                      backgroundSize: "100% auto",
                      backgroundPosition: "center center",
                      backgroundImage: `url('${backgroundImages?.macos[0]?.url}`,

                      maxHeight: "550px",
                      maxWidth: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      // height: (screenRef?.current?.offsetWidth || ratioHeight) * (ratioWidth / ratioHeight),
                      position: "relative",
                      aspectRatio: `1920/1080`,
                      padding: 0,

                      marginBottom: "1rem",
                    }}
                    ref={screenRef}
                  >
                    <div
                      className={
                        "flex-column flex-center primary-div " +
                        (isDragHover ? "primary-gradient-div" : "white-div")
                      }
                      style={{
                        margin: selectedVisualFormatConfig.imageMargin,

                        maxHeight: "75%",
                        maxWidth: "75%",

                        width: "100%",
                        height: "100%",

                        borderRadius:
                          landingVisual2FormatConfigs[0].borderRadius,
                        boxShadow: landingVisual2FormatConfigs[0].boxShadow,
                      }}
                    >
                      <div
                        className="flex-column flex-center"
                        style={{
                          justifyContent: "stretch",
                        }}
                      >
                        <p className="hide-phone">
                          {t("landingPage.drag_image_here")}
                        </p>
                        <div className="hide-phone flex-row flex-center full-width">
                          <hr
                            className="flex-1"
                            style={{ marginRight: "0.25rem" }}
                          />
                          <b
                            className="half-opacity"
                            style={{ marginBottom: "0.15rem" }}
                          >
                            {t("commons.or")}
                          </b>
                          <hr
                            className="flex-1"
                            style={{ marginLeft: "0.25rem" }}
                          />
                        </div>

                        <label
                          htmlFor="file-upload"
                          className={
                            "hide-phone button flex-row " +
                            (isDragHover ? "is-bordered" : "is-warn-gradient")
                          }
                          style={{
                            justifyContent: "flex-start",
                            height: "100%",
                            width: "100%",
                            borderRadius: "21px",
                          }}
                        >
                          <span
                            className="msymbol material-symbols-outlined"
                            style={{
                              opacity: 0.5,
                              marginLeft: "-0.25rem",
                              marginRight: "-0.25rem",
                            }}
                          >
                            image
                          </span>
                          <span style={{ marginLeft: "0.75rem" }}>
                            {t("commons.choose_file")}
                          </span>
                        </label>

                        <button
                          className={
                            "hide-phone download-button button flex-row " +
                            (isDragHover ? "is-bordered" : "is-warn-gradient")
                          }
                          style={{
                            justifyContent: "flex-start",
                            height: "100%",
                            width: "100%",
                            marginTop: "1rem",
                            borderRadius: "21px",
                          }}
                          onClick={() => {
                            capture()
                          }}
                        >
                          <span
                            className="msymbol material-symbols-outlined"
                            style={{
                              opacity: 0.5,
                              marginLeft: "-0.25rem",
                              marginRight: "-0.25rem",
                            }}
                          >
                            capture
                          </span>
                          <span style={{ marginLeft: "0.75rem" }}>
                            {t("commons.capture_screenshots")}
                          </span>
                        </button>

                        <label
                          htmlFor="file-upload"
                          className={
                            "button flex-row is-bordered hide-gt-phone"
                          }
                          style={{
                            alignItems: "center",
                            justifyContent: "flex-start",
                            height: "100%",
                            width: "calc(100% - 2rem)",
                            margin: "1rem",
                          }}
                        >
                          <span
                            className="msymbol material-symbols-outlined"
                            style={{
                              opacity: 0.5,
                              marginLeft: "-0.25rem",
                              marginRight: "-0.25rem",
                            }}
                          >
                            image
                          </span>
                          <span style={{ marginLeft: "0.75rem" }}>
                            {t("commons.choose_file")}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <input
                    id="file-upload"
                    type="file"
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                  <div className="flex-center flex-column">
                    <div
                      className="flex-row flex-column-phone"
                      style={{ marginBottom: "2rem" }}
                    >
                      <div
                        className="flex-row"
                        style={{ alignItems: "center", marginTop: "1rem" }}
                      >
                        <span
                          className="msymbols material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            fontSize: 28,
                            marginRight: "0.5rem",
                          }}
                        >
                          featured_seasonal_and_gifts
                        </span>
                        <p
                          style={{ fontSize: "0.95rem", marginRight: "2.5rem" }}
                        >
                          {t("landingPage.free")}
                        </p>
                      </div>

                      <div
                        className="flex-row"
                        style={{ alignItems: "center", marginTop: "1rem" }}
                      >
                        <span
                          className="msymbols material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            fontSize: 28,
                            marginRight: "0.5rem",
                          }}
                        >
                          stars
                        </span>
                        <p
                          style={{ fontSize: "0.95rem", marginRight: "2.5rem" }}
                        >
                          {t("landingPage.no_watermark")}
                        </p>
                      </div>

                      <div
                        className="flex-row"
                        style={{ alignItems: "center", marginTop: "1rem" }}
                      >
                        <span
                          className="msymbols material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            fontSize: 28,
                            marginRight: "0.5rem",
                          }}
                        >
                          no_accounts
                        </span>
                        <p style={{ fontSize: "0.95rem" }}>
                          {t("landingPage.no_accounts_required")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="pricing-container flex-column flex-center"
              style={{ justifyContent: "center" }}
            >
              <div
                className="pricing-content flex-row flex-center flex-reverse-column-tablet"
                style={{
                  maxWidth: 1200,
                  fontSize: 16,
                  width: "calc(100% - 2rem)",
                }}
              >
                <div className="flex-column flex-1 landing-paragraph-container-left">
                  <h2
                    className="landing-sub-title"
                    style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
                  >
                    <Trans>{t("landingPage.templates_title")}</Trans>
                  </h2>

                  <p
                    style={{
                      marginBottom: "2.5rem",
                      fontFamily: "Poppins, sans-serif",
                      width: "100%",
                      fontSize: "1rem",
                    }}
                  >
                    {t("landingPage.templates_infos")}
                  </p>

                  <Link
                    to="/editor"
                    className="button is-warn-gradient"
                    style={{
                      marginBottom: "2.5rem",
                      fontSize: "1.25rem",
                      whiteSpace: "normal",
                      height: "unset",
                    }}
                  >
                    {t("landingPage.register_create_template")}
                  </Link>
                </div>
                {/* lazyload image */}
                <img
                  style={{ display: "none" }}
                  src={brandScreens[0]?.bg}
                  alt=""
                />
                <img
                  style={{ display: "none" }}
                  src={brandScreens[1]?.bg}
                  alt=""
                />
                <img
                  style={{ display: "none" }}
                  src={brandScreens[2]?.bg}
                  alt=""
                />
                <img
                  style={{ display: "none" }}
                  src={brandScreens[0]?.image}
                  alt=""
                />
                <img
                  style={{ display: "none" }}
                  src={brandScreens[1]?.image}
                  alt=""
                />
                <img
                  style={{ display: "none" }}
                  src={brandScreens[2]?.image}
                  alt=""
                />
                <div
                  ref={feature2ContainerRef}
                  className="feature-image-container flex-column flex-1 flex-center"
                  style={{
                    width: "100%",
                    height: fearure2ContainerWidth * (9 / 16),
                  }}
                >
                  <div
                    className="feature-image flex-column flex-center"
                    style={{
                      borderRadius: 16,
                      overflow: "hidden",
                      backgroundSize: "auto 100%",
                      backgroundPosition: "center center",
                      backgroundImage: `url('${brandScreens[indexBg % brandScreens.length]?.bg}')`,

                      width: "100%",
                      height: fearure2ContainerWidth * (9 / 16),
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      aspectRatio: `${landingVisual2FormatConfigs[0].width}/${landingVisual2FormatConfigs[0].height}`,
                      padding: landingVisual2FormatConfigs[0].containerPadding,
                    }}
                    ref={feature2ScreenRef}
                  >
                    <div
                      style={{
                        margin: landingVisual2FormatConfigs[0].imageMargin,

                        marginTop: landingVisual2FormatConfigs[0].getTopMargin
                          ? landingVisual2FormatConfigs[0].getTopMargin
                          : landingVisual2FormatConfigs[0].imageMargin,
                        width: landingVisual2FormatConfigs[0].imageMaxWidth,

                        position: landingVisual2FormatConfigs[0].imagePosition,
                        top: landingVisual2FormatConfigs[0].imageAbsoluteBottom,
                      }}
                    >
                      {indexBg % brandScreens.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            maxHeight: "100%",
                            borderRadius:
                              landingVisual2FormatConfigs[0].borderRadius,
                            boxShadow: landingVisual2FormatConfigs[0].boxShadow,
                            padding: landingVisual2FormatConfigs[0].insetValue,
                            background:
                              brandScreens[indexBg % brandScreens.length]
                                .insetColor,
                            overflow: "hidden",
                          }}
                          className="screen-opacity-animation"
                        >
                          <img
                            src={`${brandScreens[indexBg % brandScreens.length]?.image}`}
                            alt=""
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              borderRadius:
                                landingVisual2FormatConfigs[0]
                                  .imageBorderRadius,
                              background: `url(${file})`,
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                      )}
                      {indexBg % brandScreens.length === 1 && (
                        <div
                          style={{
                            display: "flex",
                            maxHeight: "100%",
                            borderRadius:
                              landingVisual2FormatConfigs[0].borderRadius,
                            boxShadow: landingVisual2FormatConfigs[0].boxShadow,
                            padding: landingVisual2FormatConfigs[0].insetValue,
                            background:
                              brandScreens[indexBg % brandScreens.length]
                                .insetColor,
                            overflow: "hidden",
                          }}
                          className="screen-opacity-animation"
                        >
                          <img
                            src={`${brandScreens[indexBg % brandScreens.length]?.image}`}
                            alt=""
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              borderRadius:
                                landingVisual2FormatConfigs[0]
                                  .imageBorderRadius,
                              background: `url(${file})`,
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                      )}
                      {indexBg % brandScreens.length === 2 && (
                        <div
                          style={{
                            display: "flex",
                            maxHeight: "100%",
                            borderRadius:
                              landingVisual2FormatConfigs[0].borderRadius,
                            boxShadow: landingVisual2FormatConfigs[0].boxShadow,
                            padding: landingVisual2FormatConfigs[0].insetValue,
                            background:
                              brandScreens[indexBg % brandScreens.length]
                                .insetColor,
                            overflow: "hidden",
                          }}
                          className="screen-opacity-animation"
                        >
                          <img
                            src={`${brandScreens[indexBg % brandScreens.length]?.image}`}
                            alt=""
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              borderRadius:
                                landingVisual2FormatConfigs[0]
                                  .imageBorderRadius,
                              background: `url(${file})`,
                              boxSizing: "border-box",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={faqRef}
              className="pricing-container flex-column flex-center"
              style={{ justifyContent: "flex-start", minHeight: "unset" }}
            >
              <div
                className="pricing-content flex-column "
                style={{
                  maxWidth: 1200,
                  fontSize: 16,
                  width: "calc(100% - 2rem)",
                }}
              >
                <span
                  className="landing-sub-title"
                  style={{ fontSize: "1.5rem", marginTop: "2.5rem" }}
                >
                  <strong style={{ fontWeight: "600" }}>FAQ</strong>
                </span>
                <div
                  className="flex-row flex-column-tablet"
                  style={{
                    marginBottom: "7.5rem",
                  }}
                >
                  <div className="faq-title flex-column">
                    <h2 className="landing-sub-title">
                      <Trans>{t("landingPage.faq_title")}</Trans>
                    </h2>
                  </div>

                  <div className="faq-questions-container flex-column">
                    <div className="faq-questions flex-column">
                      <h3
                        className={selectedQuestion === 1 ? "selected" : ""}
                        onClick={() => {
                          setQuestion(1)
                        }}
                      >
                        <div className="flex-1">
                          <span className="question-text">
                            {t("landingPage.question_1")}
                          </span>
                        </div>

                        <span className="msymbols material-symbols-outlined">
                          {selectedQuestion === 1
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </h3>

                      {selectedQuestion === 1 && (
                        <div className="answer-content fade-in-top flex-column">
                          <p
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            {t("landingPage.answer_1")}
                          </p>
                        </div>
                      )}

                      <hr />

                      <h3
                        className={selectedQuestion === 2 ? "selected" : ""}
                        onClick={() => {
                          setQuestion(2)
                        }}
                      >
                        <div className="flex-1">
                          <span className="question-text">
                            {t("landingPage.question_2")}
                          </span>
                        </div>

                        <span className="msymbols material-symbols-outlined">
                          {selectedQuestion === 2
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </h3>
                      {selectedQuestion === 2 && (
                        <div className="answer-content fade-in-top flex-column">
                          <p
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            {t("landingPage.answer_2")}
                          </p>
                        </div>
                      )}

                      <hr />

                      <h3
                        className={selectedQuestion === 3 ? "selected" : ""}
                        onClick={() => {
                          setQuestion(3)
                        }}
                      >
                        <div className="flex-1">
                          <span className="question-text">
                            {t("landingPage.question_3")}
                          </span>
                        </div>

                        <span className="msymbols material-symbols-outlined">
                          {selectedQuestion === 3
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </h3>

                      {selectedQuestion === 3 && (
                        <div className="answer-content fade-in-top flex-column">
                          <p
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            {t("landingPage.answer_3")}
                          </p>
                        </div>
                      )}

                      <hr />

                      <h3
                        className={selectedQuestion === 5 ? "selected" : ""}
                        onClick={() => {
                          setQuestion(5)
                        }}
                      >
                        <div className="flex-1">
                          <span className="question-text">
                            {t("landingPage.question_5")}
                          </span>
                        </div>

                        <span className="msymbols material-symbols-outlined">
                          {selectedQuestion === 5
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </h3>

                      {selectedQuestion === 5 && (
                        <div className="answer-content fade-in-top flex-column">
                          <p
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            {t("landingPage.answer_5")}
                          </p>

                          <div
                            className="flex-row"
                            style={{
                              marginTop: "0.75rem",
                            }}
                          >
                            <Link
                              className="social-link"
                              to="https://x.com/intent/follow?screen_name=GabFrk"
                              target="_blank"
                              style={{ marginRight: "1.5rem" }}
                            >
                              <img
                                style={{ width: 24, height: 24 }}
                                src={
                                  "/assets/monochrome-social/" +
                                  (isDark ? "x-white.svg" : "x.svg")
                                }
                                alt=""
                              />
                            </Link>

                            <Link
                              className="social-link"
                              to="https://www.linkedin.com/in/gabriel-franck-546a009b/"
                              target="_blank"
                            >
                              <img
                                style={{ width: 24, height: 24 }}
                                src={
                                  "/assets/monochrome-social/" +
                                  (isDark
                                    ? "linkedin-white.svg"
                                    : "linkedin.svg")
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                        </div>
                      )}

                      <hr />

                      <h3
                        className={selectedQuestion === 6 ? "selected" : ""}
                        onClick={() => {
                          setQuestion(6)
                        }}
                      >
                        <div className="flex-1">
                          <span className="question-text">
                            {t("landingPage.question_6")}
                          </span>
                        </div>

                        <span className="msymbols material-symbols-outlined">
                          {selectedQuestion === 6
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </span>
                      </h3>

                      {selectedQuestion === 6 && (
                        <div className="answer-content fade-in-top flex-column">
                          <p
                            style={{
                              marginTop: "1rem",
                            }}
                          >
                            {t("landingPage.answer_6")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="pricing-container flex-column flex-center"
              style={{ minHeight: "unset", marginBottom: "2.5rem" }}
            >
              <div
                className="pricing-content flex-column flex-center"
                style={{
                  maxWidth: 1200,
                  fontSize: 16,
                  width: "calc(100% - 2rem)",
                  marginBottom: 0,
                  height: "100%",
                }}
              >
                <h2
                  className="landing-sub-title-center"
                  style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
                >
                  <Trans>{t("landingPage.last_part_title")}</Trans>
                </h2>

                <p className="landing-sub-infos">
                  {t("landingPage.last_part_paragraph")}
                </p>

                <Link
                  to="/editor"
                  className="button is-warn-gradient"
                  style={{
                    marginBottom: "5rem",
                    fontSize: "1.25rem",
                    whiteSpace: "normal",
                    height: "unset",
                  }}
                >
                  {t("landingPage.last_part_cta")}
                </Link>

                <div className="full-width">
                  <div className="footer-image-container">
                    <div className="footer-image-inset">
                      <img
                        className="footer-image"
                        src={`/assets/${isDark ? "landing-dark" : "landing-light"}/screen-editor.png`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="pricing-container flex-column flex-center"
              style={{
                minHeight: "unset",
                marginBottom: "7.5rem",
                marginTop: "5rem",
              }}
            >
              <div
                className="pricing-content flex-row flex-column-phone"
                style={{
                  maxWidth: 1200,
                  fontSize: 16,
                  width: "calc(100% - 2rem)",
                  marginBottom: 0,
                  height: "100%",
                }}
              >
                <div
                  className="flex-column"
                  style={{
                    width: "25%",
                    minWidth: "300px",
                    justifyContent: "flex-start",
                  }}
                >
                  <NavLink
                    to="/landing"
                    className="navbar-logo"
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      if (scrollToTop) scrollToTop()
                    }}
                  >
                    <span className="link-text flex-row flex-center">
                      <img
                        src="/logo512.png"
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "0.75rem",
                        }}
                      />

                      <h1 className="text-center">
                        Social
                        <span className="primary-gradient-red-text font-bold">
                          Screenshots
                        </span>
                      </h1>
                    </span>
                  </NavLink>
                  <p
                    style={{
                      fontSize: "0.8rem",
                      fontStyle: "italic",
                      opacity: 0.5,
                      marginTop: "1.5rem",
                    }}
                  >
                    made by
                  </p>
                  <Link
                    className="flex-row social-link"
                    to="https://x.com/intent/follow?screen_name=GabFrk"
                    target="_blank"
                    style={{
                      alignItems: "center",
                      marginTop: "0.5rem",
                    }}
                  >
                    <img
                      src="/assets/testimonial-pics/pic-1.png"
                      alt=""
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: 24,
                        marginRight: "0.75rem",
                      }}
                    />
                    <div className="flex-column">
                      <b>Gab Franck</b>
                      <i style={{ fontSize: "0.8rem" }}>@GabFrk</i>
                    </div>
                  </Link>
                  <div
                    className="flex-row"
                    style={{
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Link
                      className="social-link"
                      to="https://x.com/intent/follow?screen_name=GabFrk"
                      target="_blank"
                      style={{ marginRight: "1.5rem" }}
                    >
                      <img
                        style={{ width: 24, height: 24 }}
                        src={
                          "/assets/monochrome-social/" +
                          (isDark ? "x-white.svg" : "x.svg")
                        }
                        alt=""
                      />
                    </Link>

                    <Link
                      className="social-link"
                      to="https://www.linkedin.com/in/gabriel-franck-546a009b/"
                      target="_blank"
                    >
                      <img
                        style={{ width: 24, height: 24 }}
                        src={
                          "/assets/monochrome-social/" +
                          (isDark ? "linkedin-white.svg" : "linkedin.svg")
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                </div>

                <div className="flex-row flex-column-tablet flex-1">
                  <div className="footer-link-category flex-column">
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginBottom: "0.5rem",
                      }}
                      className="font-bold half-opacity"
                    >
                      {t("landingPage.other_tools")}
                    </h3>

                    <Link
                      className="footer-link"
                      to="https://champo.ai"
                      target="_blank"
                    >
                      ChampoAI
                    </Link>
                  </div>

                  <div className="footer-link-category not-first-cat flex-column">
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginBottom: "0.5rem",
                      }}
                      className="font-bold half-opacity"
                    >
                      {t("landingPage.links")}
                    </h3>
                    <Link className="footer-link" to="/editor">
                      {t("landingPage.editor_title")}
                    </Link>
                  </div>

                  <div className="footer-link-category not-first-cat flex-column">
                    <h3
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        marginBottom: "0.5rem",
                      }}
                      className="font-bold half-opacity"
                    >
                      {t("landingPage.legal")}
                    </h3>

                    <Link className="footer-link" to="/">
                      {t("landingPage.legal_mention")}
                    </Link>
                    <Link className="footer-link" to="/">
                      {t("landingPage.conditions")}
                    </Link>
                    <Link className="footer-link" to="/">
                      {t("landingPage.privacy")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="language-overlay flex-column flex-row-phone">
        <div className="flex-row">
          <a
            className="champo-link"
            href="https://champo.ai"
            target="_blank"
            rel="noreferrer"
          >
            {isDark ? (
              <img src="/dark_champo_ai_watermark.png" alt="" />
            ) : (
              <img src="/light_champo_ai_watermark.png" alt="" />
            )}
          </a>
        </div>
        <div className="flex-row">
          <DropdownLanguage className="full-width" />

          <button
            className="button is-bordered"
            style={{ margin: 0, marginLeft: "0.5rem", padding: "0.5rem" }}
            onClick={() => toggleDarkMode()}
          >
            <span className="msymbol material-symbols-outlined">
              {!isDark ? "dark_mode" : "light_mode"}
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default LandingPage
