import React, { Dispatch, useState } from "react"

type TranslationContextType = {
  languages: string[][]
  //   usedLang: string | null
  //   setDropdownOpen: Dispatch<string | null>
}

export const TranslationContext = React.createContext<TranslationContextType>({
  languages: [],
  //   usedLang: null,
  //   setDropdownOpen: () => {},
})

type Props = {
  children: React.ReactNode
}

export function TranslationProvider({ children }: Props) {
  //   const [dropdownOpen, setDropdownOpen] = useState<string | null>(null)
  //   const [accountDropdownOpen, setAccountDropdownOpen] = useState<string | null>(
  //     null,
  //   )
  const languages = [
    ["de", "🇩🇪", "de-DE"],
    ["en", "🇺🇸", "en-US"],
    ["es", "🇪🇸", "es-ES"],
    ["fr", "🇫🇷", "fr-FR"],
    ["it", "🇮🇹", "it-IT"],
    ["ja", "🇯🇵", "ja-JP"],
    ["ko", "🇰🇷", "ko-KR"],
    ["da", "🇰🇷", "da-DK"],
    ["nl", "🇳🇱", "nl-NL"],
    ["br", "🇧🇷", "pt-BR"],
    ["pt", "🇵🇹", "pt-PT"],
    ["vi", "🇨🇳", "vi-VN"],
    ["zh", "🇨🇳", "zh-CN"],
  ]

  return (
    <TranslationContext.Provider
      value={{
        languages,
      }}
    >
      {children}
    </TranslationContext.Provider>
  )
}
