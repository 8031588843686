import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import ReactCrop, {
  convertToPixelCrop,
  Crop,
  PixelCrop,
} from "react-image-crop"
import useWindowDimensions from "../hooks/useWindowDimensions"
import Modal from "../ui/components/Modal"

type ImageEditorContextType = {
  //   usedLang: string | null
  //   setDropdownOpen: Dispatch<string | null>
  selectedGradient: any
  setSelectedGradient: Dispatch<any | null>
  selectedBackgroundImage: any
  setSelectedBackgroundImage: Dispatch<any | null>
  ratioHeight: any
  setRatioHeight: Dispatch<any | null>
  ratioWidth: any
  setRatioWidth: Dispatch<any | null>
  featureRatioWidth: any
  setFeatureRatioWidth: Dispatch<any | null>
  titlePosition: any
  setTitlePosition: Dispatch<any | null>
  customColor1: any
  setCustomColor1: Dispatch<any | null>
  customColor2: any
  setCustomColor2: Dispatch<any | null>
  insetColor: any
  setInsetColor: Dispatch<any | null>
  shadowColor: any
  setShadowColor: Dispatch<any | null>
  showColorPicker1: any
  setShowColorPicker1: Dispatch<any | null>
  showColorPicker2: any
  setShowColorPicker2: Dispatch<any | null>
  showColorPickerInset: any
  setShowColorPickerInset: Dispatch<any | null>
  showColorPickerShadow: any
  setShowColorPickerShadow: Dispatch<any | null>
  textColor: any
  setTextColor: Dispatch<any | null>
  showColorTextPicker: any
  setShowColorTextPicker: Dispatch<any | null>
  preventDoubleSubmit: any
  setPreventDoubleSubmit: Dispatch<any | null>
  typedTitle: any
  setTypedTitle: Dispatch<any | null>
  width: any
  setwidth: Dispatch<any | null>
  featureWidth: any
  setFearureWidth: Dispatch<any | null>

  getSize: (size: number) => any
  gradients: any
  backgroundImages: any
  visualFormatConfigs: any
  landingVisualFormatConfigs: any
  selectedVisualFormat: any
  setSelectedVisualFormat: Dispatch<any | null>
  selectedVisualFormatConfig: any
  setSelectedVisualFormatConfig: Dispatch<any | null>

  paddingValue: any
  setPaddingValue: Dispatch<any | null>
  borderRadiusValue: any
  setBorderRadiusValue: Dispatch<any | null>
  insetValue: any
  setInsetValue: Dispatch<any | null>
  shadowValue: any
  setShadowValue: Dispatch<any | null>

  file: any
  setFile: Dispatch<any | null>
  bgFile: any
  setBgFile: Dispatch<any | null>
  originalFile: any
  setOriginalFile: Dispatch<any | null>

  showWatermark: boolean
  setShowWatermark: Dispatch<boolean>

  backgroundType: string
  setBackgroundType: Dispatch<string>
  wallpaperCategory: string
  setWallpaperCategory: Dispatch<string>

  landingVisual2FormatConfigs: any[]
  feature2Width: any
  setFearure2Width: Dispatch<any | null>

  visualMode: string
  setVisualMode: Dispatch<string>

  fileLoaded: boolean
  setFileLoaded: Dispatch<boolean>
  bgLoaded: boolean
  setBgLoaded: Dispatch<boolean>

  importedImageWidth: number
  setImportedImageWidth: Dispatch<number>
  importedImageHeight: number
  setImportedImageHeight: Dispatch<number>

  iportedImage: React.RefObject<HTMLImageElement> | null

  detectColor: (width: number, height: number) => void
  autoCrop: () => void
  backToOriginal: () => void

  alreadyAutoCropped: boolean
  setAlreadyAutoCropped: Dispatch<boolean>
  insetColorManuallySet: boolean
  setInsetColorManuallySet: Dispatch<boolean>

  setModalCropImage: Dispatch<boolean>

  isEditorOpen: boolean
  setIsEditorOpen: Dispatch<boolean>
}

export const ImageEditorContext = React.createContext<ImageEditorContextType>({
  //   usedLang: null,
  //   setDropdownOpen: () => {},
  selectedGradient: null,
  setSelectedGradient: () => {},
  selectedBackgroundImage: null,
  setSelectedBackgroundImage: () => {},

  ratioHeight: null,
  setRatioHeight: () => {},
  ratioWidth: null,
  setRatioWidth: () => {},
  featureRatioWidth: null,
  setFeatureRatioWidth: () => {},

  titlePosition: null,
  setTitlePosition: () => {},
  customColor1: null,
  setCustomColor1: () => {},
  customColor2: null,
  setCustomColor2: () => {},
  insetColor: null,
  setInsetColor: () => {},
  shadowColor: null,
  setShadowColor: () => {},

  showColorPicker1: null,
  setShowColorPicker1: () => {},
  showColorPicker2: null,
  setShowColorPicker2: () => {},
  showColorPickerInset: null,
  setShowColorPickerInset: () => {},
  showColorPickerShadow: null,
  setShowColorPickerShadow: () => {},

  textColor: null,
  setTextColor: () => {},
  showColorTextPicker: null,
  setShowColorTextPicker: () => {},
  preventDoubleSubmit: null,
  setPreventDoubleSubmit: () => {},
  typedTitle: null,
  setTypedTitle: () => {},
  width: null,
  setwidth: () => {},
  featureWidth: null,
  setFearureWidth: () => {},

  getSize: () => {},
  gradients: null,
  backgroundImages: [],
  visualFormatConfigs: null,
  landingVisualFormatConfigs: null,
  selectedVisualFormat: null,
  setSelectedVisualFormat: () => {},
  selectedVisualFormatConfig: null,
  setSelectedVisualFormatConfig: () => {},

  paddingValue: null,
  setPaddingValue: () => {},
  borderRadiusValue: null,
  setBorderRadiusValue: () => {},
  insetValue: null,
  setInsetValue: () => {},
  shadowValue: null,
  setShadowValue: () => {},

  file: null,
  setFile: () => {},
  originalFile: null,
  setOriginalFile: () => {},
  bgFile: null,
  setBgFile: () => {},

  showWatermark: true,
  setShowWatermark: () => {},

  backgroundType: "wallpaper",
  setBackgroundType: () => {},
  wallpaperCategory: "macos",
  setWallpaperCategory: () => {},

  landingVisual2FormatConfigs: [],
  feature2Width: null,
  setFearure2Width: () => {},

  visualMode: "image",
  setVisualMode: () => {},

  fileLoaded: false,
  setFileLoaded: () => {},
  bgLoaded: false,
  setBgLoaded: () => {},

  importedImageWidth: 0,
  setImportedImageWidth: () => {},
  importedImageHeight: 0,
  setImportedImageHeight: () => {},

  iportedImage: null,

  detectColor: () => {},
  autoCrop: () => {},
  backToOriginal: () => {},

  alreadyAutoCropped: false,
  setAlreadyAutoCropped: () => {},

  insetColorManuallySet: false,
  setInsetColorManuallySet: () => {},

  setModalCropImage: () => {},

  isEditorOpen: true,
  setIsEditorOpen: () => {},
})

type Props = {
  children: React.ReactNode
}

export function ImageEditorProvider({ children }: Props) {
  //   const [dropdownOpen, setDropdownOpen] = useState<string | null>(null)
  //   const [accountDropdownOpen, setAccountDropdownOpen] = useState<string | null>(
  //     null,
  //   )
  const { t } = useTranslation()

  const iportedImage = useRef<HTMLImageElement>(null)

  const [visualMode, setVisualMode] = useState<string>("image")
  const [file, setFile] = useState<any>()
  const [originalFile, setOriginalFile] = useState<any>()
  const [fileLoaded, setFileLoaded] = useState<boolean>(false)
  const [bgFile, setBgFile] = useState<any>()
  const [bgLoaded, setBgLoaded] = useState<boolean>(false)

  const [importedImageWidth, setImportedImageWidth] = useState<number>(0)
  const [importedImageHeight, setImportedImageHeight] = useState<number>(0)

  const [paddingValue, setPaddingValue] = useState<number>(25)
  const [borderRadiusValue, setBorderRadiusValue] = useState<number>(30)
  const [insetValue, setInsetValue] = useState<number>(15)
  const [shadowValue, setShadowValue] = useState<number>(60)

  const [backgroundType, setBackgroundType] = useState<string>("wallpaper")
  const [wallpaperCategory, setWallpaperCategory] = useState<string>("macos")

  const [selectedGradient, setSelectedGradient] = useState<number>(0)
  const [selectedBackgroundImage, setSelectedBackgroundImage] =
    useState<number>(0)
  const [ratioHeight, setRatioHeight] = useState<number>(1080)
  const [ratioWidth, setRatioWidth] = useState<number>(1920)
  const [featureRatioWidth, setFeatureRatioWidth] = useState<number>(1920)

  const [showWatermark, setShowWatermark] = useState<boolean>(false)

  const [titlePosition, setTitlePosition] = useState<string>("none")

  const [customColor1, setCustomColor1] = useState<string>("#B5153B")
  const [customColor2, setCustomColor2] = useState<string>("#EBC22F")
  const [showColorPicker1, setShowColorPicker1] = useState<boolean>(false)
  const [showColorPicker2, setShowColorPicker2] = useState<boolean>(false)
  const [insetColor, setInsetColor] = useState<string>("#fff")
  const [showColorPickerInset, setShowColorPickerInset] =
    useState<boolean>(false)
  const [shadowColor, setShadowColor] = useState<any>("#00000080")
  const [showColorPickerShadow, setShowColorPickerShadow] =
    useState<boolean>(false)

  const [textColor, setTextColor] = useState<string>("#fff")
  const [showColorTextPicker, setShowColorTextPicker] = useState<boolean>(false)

  const [preventDoubleSubmit, setPreventDoubleSubmit] = useState<boolean>(false)

  const [typedTitle, setTypedTitle] = useState<string>("")

  const [width, setwidth] = useState(0)
  const [featureWidth, setFearureWidth] = useState(0)
  const [feature2Width, setFearure2Width] = useState(0)

  const [alreadyAutoCropped, setAlreadyAutoCropped] = useState<boolean>(false)
  const [insetColorManuallySet, setInsetColorManuallySet] =
    useState<boolean>(false)

  const [isEditorOpen, setIsEditorOpen] = useState(true)

  const getSize = useCallback(
    (size: number) => {
      return width
        ? ((width / ratioWidth) * size).toFixed(1) + "px"
        : size.toFixed(1) + "px"
    },
    [width, ratioWidth],
  )

  const getLFeatureSize = useCallback(
    (size: number) => {
      return featureWidth
        ? (featureWidth / featureRatioWidth) * size + "px"
        : size + "px"
    },
    [featureWidth, featureRatioWidth],
  )

  const getLFeature2Size = useCallback(
    (size: number) => {
      return feature2Width ? (feature2Width / 1920) * size + "px" : size + "px"
    },
    [feature2Width],
  )

  const [automaticlyRemovedMark, setAutomaticlyRemovedMark] =
    useState<boolean>(false)

  useEffect(() => {
    if (!automaticlyRemovedMark) {
      setAutomaticlyRemovedMark(true)
      setShowWatermark(false)
    }
  }, [automaticlyRemovedMark])

  const visualFormatConfigs = useMemo(
    () => [
      {
        name: "Full HD - 16/9",
        resolution: "1920 x 1080",
        width: 1920,
        height: 1080,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "Square",
        resolution: "1080 x 1080",
        width: 1080,
        height: 1080,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      // {
      //   name: "OG banner",
      //   resolution: "1230 x 630",
      //   width: 1230,
      //   height: 630,
      //   borderRadius: borderRadiusValue,
      //   imageBorderRadius:
      //     borderRadiusValue - insetValue > 0
      //       ? borderRadiusValue - insetValue
      //       : 0,
      //   insetValue: insetValue,
      //   containerPadding: paddingValue,
      //   boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      // },
      {
        name: "Phone",
        resolution: "1080 x 1920",
        width: 1080,
        height: 1920,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "X (Twitter) - Landscape",
        resolution: "1600 x 900",
        width: 1600,
        height: 900,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "X (Twitter) - Portrait",
        resolution: "1080 x 1350",
        width: 1080,
        height: 1350,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "LinkedIn - Landscape",
        resolution: "1600 x 900",
        width: 1600,
        height: 900,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "LinkedIn - Portrait",
        resolution: "1080 x 1350",
        width: 1080,
        height: 1350,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "Facebook - Landscape",
        resolution: "1200 x 628",
        width: 1200,
        height: 628,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "Facebook - Portrait",
        resolution: "628 x 1200",
        width: 628,
        height: 1200,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "Instagram - Landscape",
        resolution: "1080 x 566",
        width: 1080,
        height: 566,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      {
        name: "Instagram - Portrait",
        resolution: "1080 x 1350",
        width: 1080,
        height: 1350,
        borderRadius: borderRadiusValue,
        imageBorderRadius:
          borderRadiusValue - insetValue > 0
            ? borderRadiusValue - insetValue
            : 0,
        insetValue: insetValue,
        containerPadding: paddingValue,
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      },
      // {
      //   name: "Twitter - Cover",
      //   resolution: "1500 x 500",
      //   width: 1500,
      //   height: 500,
      //   borderRadius: borderRadiusValue,
      //   imageBorderRadius:
      //     borderRadiusValue - insetValue > 0
      //       ? borderRadiusValue - insetValue
      //       : 0,
      //   fontSize: getSize(50),
      //   lineHeight: getSize(70),
      //   insetValue: getSize(insetValue),
      //   watermarkSize: getSize(20),
      //   watermarkPosition: { top: getSize(-40) },

      //   titlePosition: "top",
      //   titleMaxWidth: "80%",
      //   imageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   imageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "150%" : "150%",
      //   fakeImageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   fakeImageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "80%" : "80%",

      //   imagePosition: "absolute",
      //   imageAbsoluteBottom: "40%",
      //   fakeMarginBottom: getSize(500 * 0.6),
      //   fakeTitleHeight: getSize(500 * 0.4),

      //   titleMargin: getSize(0),
      //   bottomTitleMarginTop: getSize(0),
      //   imageMargin: getSize(0),
      //   containerPadding: getSize(0),
      //   boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      // },
      // {
      //   name: "Linkedin - Cover",
      //   resolution: "1584 x 396",
      //   width: 1584,
      //   height: 396,
      //   borderRadius: borderRadiusValue,
      //   imageBorderRadius:
      //     borderRadiusValue - insetValue > 0
      //       ? borderRadiusValue - insetValue
      //       : 0,
      //   fontSize: getSize(45),
      //   lineHeight: getSize(60),
      //   insetValue: getSize(insetValue),
      //   watermarkSize: getSize(20),
      //   watermarkPosition: { top: getSize(-40) },

      //   titlePosition: "top",
      //   titleMaxWidth: "80%",
      //   imageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   imageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "150%" : "150%",
      //   fakeImageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   fakeImageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "80%" : "80%",

      //   imagePosition: "absolute",
      //   imageAbsoluteBottom: "40%",
      //   fakeMarginBottom: getSize(396 * 0.6),
      //   fakeTitleHeight: getSize(396 * 0.4),

      //   titleMargin: getSize(0),
      //   bottomTitleMarginTop: getSize(0),
      //   imageMargin: getSize(0),
      //   containerPadding: getSize(0),
      //   boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      // },
      // {
      //   name: "Linkedin - Business Cover",
      //   resolution: "1128 x 191",
      //   width: 1128,
      //   height: 191,
      //   borderRadius: borderRadiusValue,
      //   imageBorderRadius:
      //     borderRadiusValue - insetValue > 0
      //       ? borderRadiusValue - insetValue
      //       : 0,
      //   fontSize: getSize(25),
      //   lineHeight: getSize(40),
      //   insetValue: getSize(insetValue),
      //   watermarkSize: getSize(16),
      //   watermarkPosition: { top: getSize(-30) },

      //   titlePosition: "top",
      //   titleMaxWidth: "80%",
      //   imageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   imageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "150%" : "150%",
      //   fakeImageMaxWidth:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "50%" : "50%",
      //   fakeImageMaxHeight:
      //     titlePosition !== "none" && typedTitle.length > 0 ? "80%" : "80%",

      //   imagePosition: "absolute",
      //   imageAbsoluteBottom: "33%",
      //   fakeMarginBottom: getSize((191 * 2) / 3),
      //   fakeTitleHeight: getSize(191 / 3),

      //   titleMargin: getSize(0),
      //   bottomTitleMarginTop: getSize(0),
      //   imageMargin: getSize(0),
      //   containerPadding: getSize(0),
      //   boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getSize(50)} ${getSize(100)} ${getSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getSize(30)} ${getSize(60)} ${getSize(-30)}`,
      // },
    ],
    [
      width,
      ratioWidth,
      typedTitle,
      borderRadiusValue,
      paddingValue,
      titlePosition,
      insetValue,
      showWatermark,
    ],
  )

  const landingVisualFormatConfigs = useMemo(
    () => [
      {
        name: "Full HD - 16/9",
        resolution: "1920 x 1080",
        width: 1920,
        height: 1080,
        borderRadius: getLFeatureSize(30),
        imageBorderRadius: getLFeatureSize(20),
        fontSize: getLFeatureSize(70),
        lineHeight: getLFeatureSize(100),
        insetValue: getLFeatureSize(10),
        watermarkSize: getLFeatureSize(35),
        watermarkPosition: { bottom: getLFeatureSize(-65) },

        imageMaxWidth: "85%",
        imageMaxHeight: "85%",

        imagePosition: "relative",
        imageAbsoluteBottom: 0,
        fakeMarginBottom: 0,

        titleMargin: getLFeatureSize(30),
        imageMargin: getLFeatureSize(30),
        containerPadding: getLFeatureSize(100),
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getLFeatureSize(50)} ${getLFeatureSize(100)} ${getLFeatureSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getLFeatureSize(30)} ${getLFeatureSize(60)} ${getLFeatureSize(-30)}`,
      },
      {
        name: "Phone",
        resolution: "1080 x 1920",
        width: 1080,
        height: 1920,
        borderRadius: getLFeatureSize(25),
        imageBorderRadius: getLFeatureSize(15),
        fontSize: getLFeatureSize(50),
        lineHeight: getLFeatureSize(70),
        insetValue: getLFeatureSize(10),
        watermarkSize: getLFeatureSize(30),
        watermarkPosition: { bottom: getLFeatureSize(-60) },

        imageMaxWidth: "90%",
        imageMaxHeight: "90%",

        imagePosition: "relative",
        imageAbsoluteBottom: 0,
        fakeMarginBottom: 0,

        imageMargin: getLFeatureSize(30),
        containerPadding: getLFeatureSize(10),
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getLFeatureSize(50)} ${getLFeatureSize(100)} ${getLFeatureSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getLFeatureSize(30)} ${getLFeatureSize(60)} ${getLFeatureSize(-30)}`,
      },
      {
        name: "Square",
        resolution: "1080 x 1080",
        width: 1080,
        height: 1080,
        borderRadius: getLFeatureSize(25),
        imageBorderRadius: getLFeatureSize(15),
        fontSize: getLFeatureSize(50),
        lineHeight: getLFeatureSize(70),
        insetValue: getLFeatureSize(10),
        watermarkSize: getLFeatureSize(30),
        watermarkPosition: { bottom: getLFeatureSize(-60) },

        imageMaxWidth: "90%",
        imageMaxHeight: "90%",

        imagePosition: "relative",
        imageAbsoluteBottom: 0,
        fakeMarginBottom: 0,

        imageMargin: getLFeatureSize(30),
        containerPadding: getLFeatureSize(50),
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getLFeatureSize(50)} ${getLFeatureSize(100)} ${getLFeatureSize(-20)}, rgba(0, 0, 0, 0.6) 0px ${getLFeatureSize(30)} ${getLFeatureSize(60)} ${getLFeatureSize(-30)}`,
      },
    ],
    [featureWidth],
  )

  const landingVisual2FormatConfigs = useMemo(
    () => [
      {
        name: "Full HD - 16/9",
        resolution: "1920 x 1080",
        width: 1920,
        height: 1080,
        borderRadius: getLFeature2Size(30),
        imageBorderRadius: getLFeature2Size(20),
        fontSize: getLFeature2Size(70),
        lineHeight: getLFeature2Size(100),
        insetValue: getLFeature2Size(10),
        watermarkSize: getLFeature2Size(35),
        watermarkPosition: { bottom: getLFeature2Size(-65) },

        titleMaxWidth: "80%",
        imageMaxWidth: "80%",
        imageMaxHeight: "80%",

        imagePosition: "relative",
        imageAbsoluteBottom: 0,
        fakeMarginBottom: 0,

        titleMargin: getLFeature2Size(30),
        bottomTitleMarginTop: getLFeature2Size(45),
        imageMargin: getLFeature2Size(30),
        containerPadding: getLFeature2Size(150),
        boxShadow: `rgba(50, 50, 93, 0.5) 0px ${getLFeature2Size(50)} ${getLFeature2Size(100)} ${getLFeature2Size(-20)}, rgba(0, 0, 0, 0.6) 0px ${getLFeature2Size(30)} ${getLFeature2Size(60)} ${getLFeature2Size(-30)}`,
      },
    ],
    [feature2Width],
  )

  const [selectedVisualFormat, setSelectedVisualFormat] =
    useState<string>("1920 x 1080")
  const [selectedVisualFormatConfig, setSelectedVisualFormatConfig] =
    useState<any>(visualFormatConfigs[0])

  const gradients = useMemo(
    () => [
      { start: customColor1, stop: customColor2 },
      { start: "#B5153B", stop: "#EBC22F" },
      { start: "#157E19", stop: "#B2CA1C" },
      { start: "#2844D9", stop: "#15D4C9" },
      { start: "#6D28D9", stop: "#800B5D" },
      { start: "#50073a", stop: "#c60a0a" },
      { start: "#159957", stop: "#155799" },
      { start: "#8A2387", stop: "#F27121" },
      { start: "#FF5F6D", stop: "#FFC371" },
      { start: "#00b09b", stop: "#96c93d" },
      { start: "#1A2980", stop: "#26D0CE" },
      { start: "#ff00cc", stop: "#333399" },
      { start: "#ef32d9", stop: "#89fffd" },
      { start: "#f5af19", stop: "#f12711" },
      { start: "#24C6DC", stop: "#514A9D" },
      { start: "#0575E6", stop: "#00F260" },
    ],
    [customColor1, customColor2],
  )

  const backgroundImages = useMemo(() => {
    return {
      macos: [
        { url: "/assets/editor-backgrounds/mac-os/macos-big-sur.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-big-sur-dark.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-big-sur-colorful.jpg" },
        {
          url: "/assets/editor-backgrounds/mac-os/macos-big-sur-colorful-dark.jpg",
        },
        { url: "/assets/editor-backgrounds/mac-os/macos-monterey.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-monterey-dark.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-sonoma.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-sonoma-dark.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-ventura.jpg" },
        { url: "/assets/editor-backgrounds/mac-os/macos-ventura-dark.jpg" },
      ],
      raycast: [
        { url: "/assets/editor-backgrounds/raycast/autumnal-peach.png" },
        { url: "/assets/editor-backgrounds/raycast/blossom-2.png" },
        { url: "/assets/editor-backgrounds/raycast/blushing-fire.png" },
        { url: "/assets/editor-backgrounds/raycast/bright-rain.png" },
        { url: "/assets/editor-backgrounds/raycast/floss.png" },
        { url: "/assets/editor-backgrounds/raycast/glass-rainbow.png" },
        { url: "/assets/editor-backgrounds/raycast/good-vibes.png" },
        { url: "/assets/editor-backgrounds/raycast/moonrise.png" },
        { url: "/assets/editor-backgrounds/raycast/ray-of-lights.png" },
        { url: "/assets/editor-backgrounds/raycast/rose-thorn.png" },
      ],
    }
  }, [])

  const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve)
    })
  }

  let previewUrl2 = ""
  const autoCrop = async () => {
    if (alreadyAutoCropped) {
      backToOriginal()
      return
    }

    const canvas = document.createElement("canvas")

    const ctx = canvas.getContext("2d")

    if (ctx && iportedImage?.current) {
      let ratio = importedImageWidth / 512
      if (importedImageHeight / ratio > 512) {
        ratio = importedImageHeight / 512
      }

      let screenshotWidth = importedImageWidth / ratio
      let screenshotHeight = importedImageHeight / ratio
      canvas.width = screenshotWidth
      canvas.height = screenshotHeight

      ctx.imageSmoothingEnabled = false
      ctx.drawImage(
        iportedImage.current,
        0,
        0,
        screenshotWidth,
        screenshotHeight,
      )
      ctx.imageSmoothingQuality = "high"
      let data = ctx.getImageData(0, 0, screenshotWidth, screenshotHeight, {
        colorSpace: "srgb",
      })

      let savedColor: any
      let lineBeginSavedColor: any

      let minX: number | null = null
      let minY: number | null = null
      let maxX: number | null = null
      let maxY: number | null = null

      for (let i = 0; i < data.data.length; i = i + 4) {
        const r = data.data[i]
        const g = data.data[i + 1]
        const b = data.data[i + 2]
        let currentColor = { r, g, b }
        if (!savedColor) savedColor = { r, g, b }

        let x = Math.round((i / 4) % canvas.width)
        let y = Math.trunc(i / 4 / canvas.width)

        if (
          currentColor.r > savedColor.r + 10 ||
          currentColor.g > savedColor.g + 10 ||
          currentColor.b > savedColor.b + 10 ||
          currentColor.r < savedColor.r - 10 ||
          currentColor.g < savedColor.g - 10 ||
          currentColor.b < savedColor.b - 10
        ) {
          if (minX === null || x < minX) minX = x
          if (minY === null || y < minY) minY = y
          if (maxX === null || x > maxX) maxX = x
          if (maxY === null || y > maxY) maxY = y
        }

        if (x === 0) {
          lineBeginSavedColor = { r, g, b }
        }

        if (x === canvas.width - 1) {
          savedColor = lineBeginSavedColor
        } else {
          savedColor = { r, g, b }
        }
      }

      if (minX !== null && minY !== null && maxX !== null && maxY !== null) {
        // ctx.fillStyle = "red"
        // ctx.fillRect(minX, minY, maxX - minX, maxY - minY)
        // const blob = await toBlob(canvas)
        // if (!blob) {
        //   console.error("Failed to create blob")
        //   return ""
        // }

        // if (previewUrl2) {
        //   URL.revokeObjectURL(previewUrl2)
        // }

        // previewUrl2 = URL.createObjectURL(blob)

        // setFileLoaded(false)
        // setFile(previewUrl2)

        let marginLeft: number = minX * ratio
        let marginRight: number = (canvas.width - maxX) * ratio
        let marginTop: number = minY * ratio
        let marginBottom: number = (canvas.height - maxY) * ratio

        let values = [marginLeft, marginRight, marginTop, marginBottom]
        let minMargin: number | null = null

        values.map((i: number) => {
          if (minMargin === null || i < minMargin) {
            minMargin = i
          }
          return i
        })

        canvas.remove()
        if (minMargin !== null) {
          let cropLeft = Math.round(marginLeft - minMargin)
          let cropRight = Math.round(marginRight - minMargin)
          let cropTop = Math.round(marginTop - minMargin)
          let cropBottom = Math.round(marginBottom - minMargin)

          const canvas2 = document.createElement("canvas")
          canvas2.width = importedImageWidth - cropLeft - cropRight
          canvas2.height = importedImageHeight - cropTop - cropBottom
          const ctx2 = canvas2.getContext("2d")

          if (ctx2) {
            ctx2.drawImage(
              iportedImage.current,
              0 - cropLeft,
              0 - cropTop,
              importedImageWidth,
              importedImageHeight,
            )

            const blob = await toBlob(canvas2)
            canvas2.remove()

            if (!blob) {
              console.error("Failed to create blob")
              return ""
            }

            if (previewUrl2) {
              URL.revokeObjectURL(previewUrl2)
            }

            previewUrl2 = URL.createObjectURL(blob)

            setFileLoaded(false)
            setFile(previewUrl2)
            setAlreadyAutoCropped(true)
          }
        }
      }
    }
  }

  let componentToHex = (val: string) => {
    const a = Number(val).toString(16)
    //        ^^^^^^^^^^^
    return a.length === 1 ? "0" + a : a
  }
  let rgbtohex = (rgb: string) => {
    return "#" + rgb.match(/\d+/g)!.map(componentToHex).join("")
  }

  const detectColor = (width: number, height: number) => {
    const canvas = document.createElement("canvas")

    const ctx = canvas.getContext("2d")

    if (ctx && iportedImage?.current) {
      let ratio = width / 512
      if (height / ratio > 512) {
        ratio = height / 512
      }

      let screenshotWidth = Math.round(width / ratio)
      let screenshotHeight = Math.round(height / ratio)
      canvas.width = screenshotWidth
      canvas.height = screenshotHeight

      ctx.imageSmoothingEnabled = false
      ctx.drawImage(
        iportedImage.current,
        0,
        0,
        screenshotWidth,
        screenshotHeight,
      )
      ctx.imageSmoothingQuality = "high"
      let data = ctx.getImageData(0, 0, screenshotWidth, screenshotHeight, {
        colorSpace: "srgb",
      })

      let colors: any = {}

      for (let i = 0; i < data.data.length; i = i + 4) {
        const r = data.data[i]
        const g = data.data[i + 1]
        const b = data.data[i + 2]
        if (colors[`rgb(${r}, ${g}, ${b})`]) {
          colors[`rgb(${r}, ${g}, ${b})`] += 1
        } else {
          colors[`rgb(${r}, ${g}, ${b})`] = 1
        }
      }

      let sortable = []

      for (var c in colors) {
        sortable.push([c, colors[c]])
      }

      sortable.sort(function (a, b) {
        return b[1] - a[1]
      })

      console.log(rgbtohex(sortable[0][0]))
      setInsetColor(rgbtohex(sortable[0][0]))
    }
  }

  const backToOriginal = () => {
    setAlreadyAutoCropped(false)
    setFile(originalFile)
  }

  const { windowHeight, windowWidth } = useWindowDimensions()
  // MANUAL CROP
  let previewUrl = ""

  const [modalCropImage, setModalCropImage] = useState(false)
  const [crop, setCrop] = useState<Crop>()
  const imgRef = useRef<HTMLImageElement>(null)

  const canvasPreview = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
  ) => {
    const ctx = canvas.getContext("2d")

    if (!ctx) {
      throw new Error("No 2d context")
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = "high"

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    // const rotateRads = rotate * TO_RADIANS
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 3) Rotate around the origin
    // ctx.rotate(rotateRads)
    // 2) Scale the image
    ctx.scale(scale, scale)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )

    ctx.restore()
  }

  const imgPreview = async (
    image: HTMLImageElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0,
  ) => {
    const canvas = document.createElement("canvas")
    canvasPreview(image, canvas, crop, scale, rotate)

    const blob = await toBlob(canvas)
    canvas.remove()

    if (!blob) {
      console.error("Failed to create blob")
      return ""
    }

    if (previewUrl) {
      URL.revokeObjectURL(previewUrl)
    }

    previewUrl = URL.createObjectURL(blob)
    setModalCropImage(false)
    setFileLoaded(false)
    setFile(previewUrl)
  }

  const maxWidthCrop = useMemo(() => {
    let ratio = importedImageWidth / importedImageHeight
    let maxHeight = windowHeight * 0.8 - (36 + 40 + 68)
    let maxWidth = 1100 - 32
    if (windowWidth < 1100) maxWidth = windowWidth - 32

    if (maxHeight * ratio > maxWidth) {
      return maxWidth
    } else {
      return maxHeight * ratio
    }
  }, [importedImageWidth, importedImageHeight, windowHeight, windowWidth])

  useEffect(() => {
    const color1 = localStorage.getItem("customColor1")
    if (color1) {
      setCustomColor1(color1)
    }

    const color2 = localStorage.getItem("customColor2")
    if (color2) {
      setCustomColor2(color2)
    }

    const paddingVal = localStorage.getItem("paddingVal")
    if (paddingVal) {
      setPaddingValue(Number(paddingVal))
    }
    const borderRadiusVal = localStorage.getItem("borderRadiusVal")
    if (borderRadiusVal) {
      setBorderRadiusValue(Number(borderRadiusVal))
    }
    const insetVal = localStorage.getItem("insetVal")
    if (insetVal) {
      setInsetValue(Number(insetVal))
    }

    const shadowVal = localStorage.getItem("shadowVal")
    if (shadowVal) {
      setShadowValue(Number(shadowVal))
    }

    const shadowCol = localStorage.getItem("shadowColor")
    if (shadowCol) {
      setShadowColor(shadowCol)
    }

    const bgType = localStorage.getItem("backgroundType")
    if (bgType) {
      setBgLoaded(false)
      if (bgType === "macos" || bgType === "raycast") {
        setBackgroundType("wallpaper")
        setWallpaperCategory(bgType)
      } else {
        setBackgroundType(bgType)
      }
    }
    const wallpaperCat = localStorage.getItem("wallpaperCategory")
    if (wallpaperCat) {
      setBgLoaded(false)
      setWallpaperCategory(wallpaperCat)
    }
    const selectedBgImage = localStorage.getItem("selectedBackgroundImage")
    if (selectedBgImage) {
      setSelectedBackgroundImage(Number(selectedBgImage))
    }
    const selectedGrad = localStorage.getItem("selectedGradient")
    if (selectedGrad) {
      setSelectedGradient(Number(selectedGrad))
    }

    const txtColor = localStorage.getItem("textColor")
    if (txtColor) {
      setTextColor(txtColor)
    }
  }, [])

  useEffect(() => {
    const currentConfig = visualFormatConfigs.find(
      (i: any) => selectedVisualFormat === i.resolution,
    )

    // if (currentConfig?.titlePosition) {
    //   setTitlePosition(currentConfig?.titlePosition)
    // }
    setSelectedVisualFormatConfig(currentConfig)
  }, [
    selectedVisualFormat,
    visualFormatConfigs,
    borderRadiusValue,
    paddingValue,
  ])

  return (
    <ImageEditorContext.Provider
      value={{
        selectedGradient,
        setSelectedGradient,
        selectedBackgroundImage,
        setSelectedBackgroundImage,
        ratioHeight,
        setRatioHeight,
        ratioWidth,
        setRatioWidth,
        featureRatioWidth,
        setFeatureRatioWidth,
        titlePosition,
        setTitlePosition,
        customColor1,
        setCustomColor1,
        customColor2,
        setCustomColor2,
        showColorPicker1,
        setShowColorPicker1,
        showColorPicker2,
        setShowColorPicker2,
        preventDoubleSubmit,
        setPreventDoubleSubmit,
        typedTitle,
        setTypedTitle,
        width,
        setwidth,
        featureWidth,
        setFearureWidth,

        getSize,
        gradients,
        backgroundImages,

        visualFormatConfigs,
        landingVisualFormatConfigs,
        selectedVisualFormat,
        setSelectedVisualFormat,
        selectedVisualFormatConfig,
        setSelectedVisualFormatConfig,

        paddingValue,
        setPaddingValue,
        borderRadiusValue,
        setBorderRadiusValue,
        insetValue,
        setInsetValue,
        shadowValue,
        setShadowValue,

        file,
        setFile,
        bgFile,
        setBgFile,
        originalFile,
        setOriginalFile,

        insetColor,
        setInsetColor,
        showColorPickerInset,
        setShowColorPickerInset,

        shadowColor,
        setShadowColor,
        showColorPickerShadow,
        setShowColorPickerShadow,

        textColor,
        setTextColor,
        showColorTextPicker,
        setShowColorTextPicker,

        showWatermark,
        setShowWatermark,

        backgroundType,
        setBackgroundType,
        wallpaperCategory,
        setWallpaperCategory,

        landingVisual2FormatConfigs,
        feature2Width,
        setFearure2Width,

        visualMode,
        setVisualMode,

        bgLoaded,
        setBgLoaded,
        fileLoaded,
        setFileLoaded,

        importedImageWidth,
        setImportedImageWidth,
        importedImageHeight,
        setImportedImageHeight,

        iportedImage,

        detectColor,
        autoCrop,
        backToOriginal,

        alreadyAutoCropped,
        setAlreadyAutoCropped,
        insetColorManuallySet,
        setInsetColorManuallySet,

        setModalCropImage,

        isEditorOpen,
        setIsEditorOpen,
      }}
    >
      {children}

      <img
        ref={iportedImage}
        src={file}
        alt=""
        style={{
          display: "none",
        }}
        onLoad={(e) => {
          setImportedImageWidth(e.currentTarget.naturalWidth)
          setImportedImageHeight(e.currentTarget.naturalHeight)
          setFileLoaded(true)
          if (!insetColorManuallySet) {
            detectColor(
              e.currentTarget.naturalWidth,
              e.currentTarget.naturalHeight,
            )
          }
        }}
      />

      <Modal
        isShown={modalCropImage}
        closeModal={() => {
          setModalCropImage(false)
        }}
        containerStyle={{
          width: maxWidthCrop + 32 + "px",
          maxWidth: "calc(100% - 2rem)",
          maxHeight: "80%",
          position: "relative",
        }}
      >
        <h1 className="modal-title">Crop</h1>

        <div
          className="flex-row"
          style={{ width: maxWidthCrop + "px", maxWidth: "100%" }}
        >
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img ref={imgRef} src={file} alt="" />
          </ReactCrop>
        </div>

        <div
          className="flex-row"
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="button is-warn-gradient"
            onClick={() => {
              if (imgRef.current && crop)
                imgPreview(
                  imgRef.current,
                  convertToPixelCrop(crop, crop?.width, crop?.height),
                  1,
                  0,
                )
            }}
          >
            {t("commons.validate")}
          </button>
        </div>
      </Modal>
    </ImageEditorContext.Provider>
  )
}
