import React, { Dispatch, useEffect, useState } from "react"

type UIContextType = {
  dropdownOpen: string | null
  setDropdownOpen: Dispatch<string | null>
  accountDropdownOpen: string | null
  setAccountDropdownOpen: Dispatch<string | null>

  toggleDarkMode: () => void
  isDark: boolean
}

export const UIContext = React.createContext<UIContextType>({
  dropdownOpen: null,
  setDropdownOpen: () => {},
  accountDropdownOpen: null,
  setAccountDropdownOpen: () => {},

  toggleDarkMode: () => {},
  isDark: false,
})

type Props = {
  children: React.ReactNode
}

export function UIProvider({ children }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null)
  const [accountDropdownOpen, setAccountDropdownOpen] = useState<string | null>(
    null,
  )

  // Le thème est light par défaut.
  const [isDark, setIsDark] = useState(false)

  useEffect(() => {
    if (isDark && document.documentElement.className === "theme--light") {
      document.documentElement.classList.remove("theme--light")
      document.documentElement.classList.add("theme--dark")
    }
    if (!isDark && document.documentElement.className === "theme--dark") {
      document.documentElement.classList.remove("theme--dark")
      document.documentElement.classList.add("theme--light")
    }
  }, [isDark])

  useEffect(() => {
    const existingSetting = localStorage.getItem("isDark") ?? "false"
    if (existingSetting === "true") {
      setIsDark(true)
    }

    if (existingSetting === "false") {
      setIsDark(false)
    }

    if(!existingSetting){
      setIsDark(false)
    }
  }, [])

  const toggleDarkMode = () => {
    if(isDark === false){
      localStorage.setItem("isDark", "true")
    }
    if(isDark === true){
      localStorage.setItem("isDark", "false")
    }
    setIsDark(!isDark)
  }

  return (
    <UIContext.Provider
      value={{
        dropdownOpen: dropdownOpen,
        setDropdownOpen: setDropdownOpen,
        accountDropdownOpen: accountDropdownOpen,
        setAccountDropdownOpen: setAccountDropdownOpen,

        isDark,
        toggleDarkMode: toggleDarkMode,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}
