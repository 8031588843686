import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { frFR } from "./fr-FR"
import { deDE } from "./de-DE"
import { enUS } from "./en-US"
import { esES } from "./es-ES"
import { itIT } from "./it-IT"
import { jaJP } from "./ja-JP"
import { koKR } from "./ko-KR"
import { ptBR } from "./pt-BR"
import { ptPT } from "./pt-PT"
import { zhCN } from "./zh-CN"
import { nlNL } from "./nl-NL"
import { viVN } from "./vi-VN"
import { daDK } from "./da-DK"

//empty for now
const resources = {
  "fr-FR": { translation: frFR },
  "de-DE": { translation: deDE },
  "en-US": { translation: enUS },
  "es-ES": { translation: esES },
  "it-IT": { translation: itIT },
  "ja-JP": { translation: jaJP },
  "ko-KR": { translation: koKR },
  "da-DK": { translation: daDK },
  "nl-NL": { translation: nlNL },
  "pt-BR": { translation: ptBR },
  "pt-PT": { translation: ptPT },
  "vi-VN": { translation: viVN },
  "zh-CN": { translation: zhCN },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
    compatibilityJSON: "v3",
    resources,
    fallbackLng: "en-US",
    supportedLngs: [
      "fr-FR",
      "de-DE",
      "en-US",
      "es-ES",
      "it-IT",
      "ja-JP",
      "ko-KR",
      "da-DK",
      "nl-NL",
      "pt-BR",
      "pt-PT",
      "vi-VN",
      "zh-CN",
    ],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n

// export const i18n = new I18n({
//     'fr': frFR,
//     'en': enUS,
//     'nl': nlNL
// });

// // Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;

// // When a value is missing from a language it'll fallback to another language with the key present.
// i18n.enableFallback = true;
// i18n.defaultLocale = 'fr';
