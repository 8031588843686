import auth from "./auth.json"
import credits from "./credits.json"
import commons from "./commons.json"
import landingPage from "./landingPage.json"

export const daDK = {
  auth,
  credits,
  commons,
  landingPage,
}
