import React, { CSSProperties, useContext, useRef } from "react"
import { UIContext } from "../../context/UI"

type Props = {
  title?: string | React.ReactNode
  className?: string
  iconButton?: string
  buttonClassName?: string
  selected?: boolean
  rightMenu?: boolean
  disabled?: boolean
  customMenuStyle?: CSSProperties
  style?: CSSProperties
  buttonStyle?: CSSProperties
  iconStyle?: CSSProperties
  children?: React.ReactNode
}

export const Dropdown = ({
  children,
  title,
  iconButton,
  className,
  buttonClassName,
  selected,
  rightMenu,
  disabled,
  customMenuStyle,
  style,
  buttonStyle,
  iconStyle,
}: Props) => {
  const { dropdownOpen, setDropdownOpen } = useContext(UIContext)
  const currentId = useRef("id" + Math.random().toString(16).slice(2))
  return (
    <div
      className={
        "dropdown " +
        (dropdownOpen === currentId.current ? "is-active " : "") +
        className
      }
      style={style ? style : {}}
    >
      <div className="dropdown-trigger full-width">
        <button
          className={"button dropdown-button full-width " + buttonClassName}
          aria-controls="dropdown-menu"
          disabled={disabled}
          style={buttonStyle ? buttonStyle : {}}
          onClick={(e) => {
            e.stopPropagation()
            if (!disabled) {
              if (dropdownOpen) {
                setDropdownOpen(null)
                setDropdownOpen(currentId.current)
              } else {
                setDropdownOpen(currentId.current)
              }
            }
          }}
          onBlur={() => {
            setDropdownOpen(null)
          }}
        >
          {title && (
            <span
              className={
                "text-ellipsis flex-1 " + (selected ? "font-bold" : "")
              }
            >
              {title}
            </span>
          )}

          {iconButton && (
            <span
              className="msymbol material-symbols-outlined"
              style={iconStyle}
            >
              {iconButton}
            </span>
          )}

          {!iconButton && (
            <span className="icon is-small">
              {dropdownOpen === currentId.current ? (
                <span className="msymbol material-symbols-outlined">
                  keyboard_arrow_up
                </span>
              ) : (
                <span className="msymbol material-symbols-outlined">
                  keyboard_arrow_down
                </span>
              )}
            </span>
          )}
        </button>
      </div>

      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={
          customMenuStyle
            ? customMenuStyle
            : rightMenu
              ? { top: 40, right: "0rem", left: "unset" }
              : { top: 40, left: "0rem" }
        }
      >
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  )
}

export default Dropdown
