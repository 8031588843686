import { CSSProperties } from "react"

export const AnimatedLoader = ({
  noContainer,
  smallNoContainer,
  style
}: {
  noContainer?: boolean
  smallNoContainer?: boolean
  style?: CSSProperties
}) => {
  if (smallNoContainer) {
    return (
      <div style={style} className="animated-loader smaller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  } else if (noContainer) {
    return (
      <div style={style} className="animated-loader small">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  } else {
    return (
      <div style={style} className="animated-loader-container full-width flex-column flex-center">
        <div className="animated-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}

export default AnimatedLoader
