import React, { CSSProperties } from "react"

type Props = {
  closeModal: () => void
  isShown: boolean
  children: React.ReactNode
  containerStyle?: CSSProperties
  wrapperStyle?: CSSProperties
  overflow?: string
}

export const Modal = ({
  closeModal,
  isShown,
  children,
  containerStyle,
  wrapperStyle,
  overflow,
}: Props) => {
  if (isShown) {
    return (
      <div
        className="modal-wrapper flex-column flex-center"
        style={{ ...wrapperStyle }}
      >
        <div
          className="modal-overlay flex-column flex-center"
          onClick={closeModal}
        ></div>
        <div
          className="modal-container"
          style={{ ...containerStyle, overflow: overflow ? overflow : "auto" }}
        >
          {children}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default Modal
