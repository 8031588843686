import JSConfetti from "js-confetti"
import { useCallback, useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { Trans, useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

export const ScreenSuccess = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = "Screen success - SocialScreenshots"

    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.send({
        hitType: "pageview",
        page: "/screen-success",
        title: "ScreenSuccess",
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const jsConfetti = new JSConfetti()

    jsConfetti.addConfetti({
      confettiNumber: 300,
    })
  }, [])

  const [remainingSecs, setRemainingSecs] = useState<number>(7)

  const toto = useCallback(() => {
    setRemainingSecs((remainingSecs) => {
      if (remainingSecs - 1 <= 0) window.close()
      return remainingSecs - 1
    })
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      toto()
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const closeWindow = () => {
    window.close()
  }
  return (
    <>
      <div
        className="full-width flex-column flex-center"
        style={{ minHeight: "100%" }}
      >
        <NavLink
          to="/landing"
          className="navbar-logo"
          style={{ width: "unset", margin: "1rem" }}
        >
          <ul className={"navbar-nav "}>
            <span className="link-text flex-row flex-center">
              <img
                src="/logo512.png"
                alt=""
                style={{ width: 40, height: 40, marginRight: "0.75rem" }}
              />

              <h1 className="text-center">
                Social
                <span className="primary-gradient-red-text font-bold">
                  Screenshots
                </span>
              </h1>
              {/* <div className="soon-div" style={{ fontSize: 16, marginRight: 0 }}>
              beta
            </div> */}
            </span>
          </ul>
        </NavLink>
        <div className="bordered-div flex-center" style={{ maxWidth: 475 }}>
          <div className="fade-in-top" style={{ textAlign: "center" }}>
            <span
              className="msymbol material-symbols-outlined success-text"
              style={{ fontSize: 200 }}
            >
              check_circle
            </span>
          </div>

          <h1
            style={{
              textAlign: "center",
              fontSize: 28,
              fontFamily: "Poppins, sans-serif",
              fontWeight: 800,
              marginBottom: "1.5rem",
            }}
          >
            {t("landingPage.success_screenshot")}
          </h1>

          <p style={{ textAlign: "center", marginBottom: "0.5rem" }}>
          <Trans>{t("landingPage.back_to_main_tab")}</Trans>
          </p>
          <p
            style={{
              textAlign: "center",
              marginBottom: "1.5rem",
              opacity: 0.5,
            }}
          >
            <Trans>{t("landingPage.close_tab_countdown", { count: remainingSecs })}</Trans>
          </p>
          <button
            className="button is-warn-gradient full-width"
            onClick={closeWindow}
          >
            <Trans>{t("landingPage.close_now")}</Trans>
          </button>
        </div>
      </div>
    </>
  )
}

export default ScreenSuccess
