import { useCallback, useContext, useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { UIContext } from "../../context/UI"

import { ImageEditorContext } from "../../context/ImageEditor"
import AnimatedLoader from "../components/AnimatedLoader"
import Dropdown from "../components/Dropdown"
import EditorBar from "../navbar/EditorBar"

function ImageEditor() {
  const { t } = useTranslation()

  const {
    selectedGradient,
    ratioHeight,
    ratioWidth,
    preventDoubleSubmit,
    setPreventDoubleSubmit,
    setwidth,
    gradients,
    selectedVisualFormatConfig,
    showWatermark,
    backgroundType,
    insetColor,
    shadowColor,
    shadowValue,

    file,
    setFile,
    setOriginalFile,

    backgroundImages,
    selectedBackgroundImage,

    visualMode,

    bgFile,

    bgLoaded,
    setBgLoaded,
    fileLoaded,
    setFileLoaded,

    importedImageWidth,
    importedImageHeight,

    iportedImage,

    wallpaperCategory,

    detectColor,
    autoCrop,

    alreadyAutoCropped,

    setInsetColorManuallySet,
    setAlreadyAutoCropped,

    setModalCropImage,

    isEditorOpen,
  } = useContext(ImageEditorContext)

  const topRef = useRef<null | HTMLDivElement>(null)

  const { setDropdownOpen } = useContext(UIContext)

  useEffect(() => {
    document.title = "Editor - SocialScreenshots"

    if (process.env.REACT_APP_ENV === "production") {
      // posthog?.capture("$pageview")

      ReactGA.send({
        hitType: "pageview",
        page: "/editor",
        title: "Editor",
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any) => {
    setFileLoaded(false)
    setAlreadyAutoCropped(false)
    setInsetColorManuallySet(false)
    setFile(URL.createObjectURL(e.target.files[0]))
    setOriginalFile(URL.createObjectURL(e.target.files[0]))
  }

  const screenRef = useRef<HTMLDivElement>(null)
  const bgImage = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setwidth(entries[0].contentRect.width)
    })
    if (screenRef.current) {
      observer.observe(screenRef.current)
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (screenRef.current) observer.unobserve(screenRef.current)
    }
  }, [])

  const capture = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia()
    // get correct video track
    const track = stream.getVideoTracks()[0]
    // init Image Capture and not Video stream

    const imageCapture = new (window as any).ImageCapture(track)
    // take first frame only
    const bitmap = await imageCapture.grabFrame()
    // destory video track to prevent more recording / mem leak
    track.stop()
    window.open(process.env.REACT_APP_FRONT_URL + "/screen-success")

    const canvas = document.createElement("canvas")
    // this could be a document.createElement('canvas') if you want
    // draw weird image type to canvas so we can get a useful image
    canvas.width = bitmap.width
    canvas.height = bitmap.height
    const context = canvas.getContext("2d")
    context?.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
    const image = canvas.toDataURL()
    canvas.remove()
    setFileLoaded(false)
    setAlreadyAutoCropped(false)
    setInsetColorManuallySet(false)
    setFile(image)
    setOriginalFile(image)
  }

  const [isDragHover, setIsDragHover] = useState<boolean>(false)

  const onDragOver = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    event.preventDefault()
    setIsDragHover(true)
  }

  const onDragEnter = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(true)
  }

  const onDragEnd = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(false)
  }

  const onFileDrop = (e: any) => {
    let event = e
    event.stopPropagation()
    event.preventDefault()
    setIsDragHover(false)

    if (event?.dataTransfer.files) {
      if (event?.dataTransfer.files) {
        setFileLoaded(false)
        setInsetColorManuallySet(false)
        setAlreadyAutoCropped(false)
        setFile(URL.createObjectURL(event?.dataTransfer.files[0]))
        setOriginalFile(URL.createObjectURL(event?.dataTransfer.files[0]))
      }
    }
  }
  let downloadUrl = ""

  const onButtonClick = async () => {
    if (canvasRef.current) {
      const link = document.createElement("a")
      link.download = "socialscreenshots-" + new Date().toLocaleString()
      const blob = await toBlobExport(canvasRef.current)

      if (!blob) {
        console.error("Failed to create blob")
        setPreventDoubleSubmit(false)
        return ""
      }

      if (downloadUrl) {
        URL.revokeObjectURL(downloadUrl)
      }

      downloadUrl = URL.createObjectURL(blob)
      link.href = downloadUrl
      link.click()
      link.remove()

      setPreventDoubleSubmit(false)

      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "Screenshots",
          action: "generate_screenshot",
          label: backgroundType,
        })
      }
    } else {
      setPreventDoubleSubmit(false)
    }
  }

  const [isChromium, setIsChromium] = useState<boolean | null>(null)

  useEffect(() => {
    if (isChromium === null) {
      var isChromiumBased = !!(window as any).chrome
      setIsChromium(isChromiumBased)
    }
  }, [])

  const [inputCode, setInputCode] = useState("")
  const onChange = useCallback((value: any) => {
    setInputCode(value)
  }, [])

  // useEffect(() => {
  //   const observer = new ResizeObserver((entries) => {
  //     setImportedImageWidth(entries[0].contentRect.width)
  //     setImportedImageHeight(entries[0].contentRect.height)
  //   })
  //   if (iportedImage?.current) {
  //     observer.observe(iportedImage.current)
  //   }

  //   return () => {
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     if (iportedImage?.current) observer.unobserve(iportedImage.current)
  //   }
  // }, [])

  const [exportTypeName, setExportTypeName] = useState<string>("PNG")
  const [exportType, setExportType] = useState<string>("image/png")

  const toBlobExport = useCallback(
    (canvas: HTMLCanvasElement): Promise<Blob | null> => {
      console.log(exportType)
      return new Promise((resolve) => {
        canvas.toBlob(resolve, exportType, 1)
      })
    },
    [exportType],
  )

  const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve)
    })
  }

  const exportFormats = [
    {
      name: "PNG",
      format: "image/png",
    },
    {
      name: "JPG",
      format: "image/jpeg",
    },
    {
      name: "WebP",
      format: "image/webp",
    },
  ]

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const roundedImage = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number,
    ) => {
      if (iportedImage?.current) {
        ctx.fillStyle = "#000000"

        ctx.beginPath()
        ctx.roundRect(x, y, width, height, radius)
        ctx.clip()

        ctx.drawImage(iportedImage.current, x, y, width, height)
        ctx.restore()
      }
    },
    [],
  )

  const handlePaste = async (event: any) => {
    try {
      if (!navigator.clipboard) {
        console.error("Clipboard API not available")
        return
      }

      const clipboardItems = await navigator.clipboard.read()

      for (const clipboardItem of clipboardItems) {
        const imageTypes = clipboardItem.types.find((type) =>
          type.startsWith("image/"),
        )

        if (imageTypes) {
          const blob = await clipboardItem.getType(imageTypes)
          const url = URL.createObjectURL(blob)
          setFileLoaded(false)
          setInsetColorManuallySet(false)
          setAlreadyAutoCropped(false)
          setFile(url)
          setOriginalFile(url)

          break // Assuming we need the first image
        }
      }
    } catch (err) {
      console.error("Failed to read clipboard:", err)
    }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas) {
      const ctx = canvas.getContext("2d")

      if (!ctx) {
        throw new Error("No 2d context")
      }

      canvas.width = selectedVisualFormatConfig.width
      canvas.height = selectedVisualFormatConfig.height

      // Draw background

      if (backgroundType === "gradient") {
        const gradient = ctx.createLinearGradient(
          0,
          0,
          selectedVisualFormatConfig.width,
          selectedVisualFormatConfig.height,
        )

        // Add three color stops
        gradient.addColorStop(0, gradients[selectedGradient].start)
        gradient.addColorStop(1, gradients[selectedGradient].stop)

        // Set the fill style and draw a rectangle
        ctx.fillStyle = gradient
        ctx.fillRect(
          0,
          0,
          selectedVisualFormatConfig.width,
          selectedVisualFormatConfig.height,
        )
      } else {
        if (bgImage.current) {
          let xStart =
            0 -
            ((1920 *
              (selectedVisualFormatConfig.height /
                selectedVisualFormatConfig.width)) /
              2 -
              1080 / 2)

          ctx.drawImage(
            bgImage.current,
            xStart > 0 ? 0 : xStart,
            0,
            1920 *
              (selectedVisualFormatConfig.height >
              selectedVisualFormatConfig.width
                ? selectedVisualFormatConfig.height /
                  selectedVisualFormatConfig.width
                : 1),
            selectedVisualFormatConfig.height,
          )
        }
      }

      let ratio =
        importedImageWidth /
        (selectedVisualFormatConfig.width *
          ((100 - selectedVisualFormatConfig.containerPadding) / 100))
      if (
        importedImageHeight / ratio >
        selectedVisualFormatConfig.height *
          ((100 - selectedVisualFormatConfig.containerPadding) / 100)
      ) {
        ratio =
          importedImageHeight /
          (selectedVisualFormatConfig.height *
            ((100 - selectedVisualFormatConfig.containerPadding) / 100))
      }
      let screenshotWidth = importedImageWidth / ratio
      let screenshotHeight = importedImageHeight / ratio
      let marginY = (selectedVisualFormatConfig.height - screenshotHeight) / 2
      let marginX = (selectedVisualFormatConfig.width - screenshotWidth) / 2

      if (fileLoaded) {
        // Prepare the shadow
        ctx.shadowBlur = shadowValue
        ctx.shadowColor = shadowColor
        ctx.shadowOffsetX = 0
        ctx.shadowOffsetY = shadowValue / 2
        ctx.roundRect(
          marginX - selectedVisualFormatConfig.insetValue,
          marginY - selectedVisualFormatConfig.insetValue,
          screenshotWidth + 2 * selectedVisualFormatConfig.insetValue,
          screenshotHeight + 2 * selectedVisualFormatConfig.insetValue,
          selectedVisualFormatConfig.borderRadius,
        )
        ctx.fill()

        ctx.resetTransform()
        ctx.shadowBlur = 0
        ctx.shadowOffsetX = 0
        ctx.shadowOffsetY = 0
        ctx.fillStyle = insetColor
        ctx.beginPath()
        ctx.roundRect(
          marginX - selectedVisualFormatConfig.insetValue,
          marginY - selectedVisualFormatConfig.insetValue,
          screenshotWidth + 2 * selectedVisualFormatConfig.insetValue,
          screenshotHeight + 2 * selectedVisualFormatConfig.insetValue,
          selectedVisualFormatConfig.borderRadius,
        )

        if (bgImage.current) {
          ctx.clip()
          ctx.drawImage(
            bgImage.current,
            0 -
              ((1920 *
                (selectedVisualFormatConfig.height /
                  selectedVisualFormatConfig.width)) /
                2 -
                1080 / 2),
            0,
            1920 *
              (selectedVisualFormatConfig.height >
              selectedVisualFormatConfig.width
                ? selectedVisualFormatConfig.height /
                  selectedVisualFormatConfig.width
                : 1),
            selectedVisualFormatConfig.height,
          )
          ctx.restore()
        }
        ctx.fill()

        ctx.resetTransform()

        roundedImage(
          ctx,
          marginX,
          marginY,
          screenshotWidth,
          screenshotHeight,
          selectedVisualFormatConfig.imageBorderRadius,
        )
      }
    }
  }, [
    selectedVisualFormatConfig,
    importedImageWidth,
    importedImageHeight,
    selectedBackgroundImage,
    insetColor,
    backgroundImages,
    backgroundType,
    bgLoaded,
    selectedGradient,
    gradients,
    fileLoaded,
    shadowColor,
    shadowValue,
    roundedImage,
  ])

  return (
    <>
      <div className="top-editorbar hide-phone" onPaste={handlePaste}>
        <button
          className="download-button button is-bordered"
          style={{ marginRight: "0.75rem" }}
          onClick={() => {
            setModalCropImage(true)
          }}
          disabled={!file}
        >
          <span
            className="msymbol material-symbols-outlined"
            style={{
              opacity: 0.5,
              marginLeft: "-0.25rem",
              marginRight: "-0.25rem",
            }}
          >
            crop
          </span>
          <span className="hide-tablet" style={{ marginLeft: "0.75rem" }}>
            {t("landingPage.crop")}
          </span>
        </button>

        <button
          className="download-button button is-bordered"
          style={{ marginRight: "0.75rem" }}
          onClick={() => {
            autoCrop()
          }}
          disabled={!file}
        >
          {!alreadyAutoCropped ? (
            <>
              <span
                className="msymbol material-symbols-outlined"
                style={{
                  opacity: 0.5,
                  marginLeft: "-0.25rem",
                  marginRight: "-0.25rem",
                }}
              >
                photo_size_select_small
              </span>
              <span className="hide-tablet" style={{ marginLeft: "0.75rem" }}>
                {t("landingPage.auto_crop")}
              </span>
              <span
                className="hide-gt-tablet"
                style={{ marginLeft: "0.75rem" }}
              >
                Auto
              </span>
            </>
          ) : (
            <>
              <span
                className="msymbol material-symbols-outlined"
                style={{
                  opacity: 0.5,
                  marginLeft: "-0.25rem",
                  marginRight: "-0.25rem",
                }}
              >
                undo
              </span>
              <span className="hide-tablet" style={{ marginLeft: "0.75rem" }}>
                {t("landingPage.back_to_original")}
              </span>
            </>
          )}
        </button>

        <div className="flex-1"></div>

        <Link
          className="flex-row social-link hide-phone"
          to="https://x.com/intent/follow?screen_name=GabFrk"
          target="_blank"
          style={{
            alignItems: "center",
            height: "40px",
          }}
        >
          <div className="flex-column" style={{ textAlign: "right" }}>
            <b
              style={{ fontSize: "0.75rem", fontStyle: "italic" }}
              className="warn-text"
            >
              made with ❤️ by{" "}
            </b>

            <b style={{ fontSize: "0.85rem" }}>Gab Franck</b>
          </div>
          <img
            src="/assets/testimonial-pics/pic-1.png"
            alt=""
            style={{
              width: 40,
              height: 40,
              borderRadius: 24,
              marginLeft: "0.75rem",
            }}
          />
        </Link>

        <Link
          to="https://x.com/intent/follow?screen_name=GabFrk"
          target="_blank"
          className="warn-bordered-link flex-row flex-center"
          style={{
            height: "40px",
            padding: "0 0.5rem",
            fontSize: "0.8rem",
            lineHeight: 1.15,
            marginLeft: "1rem",
            textAlign: "center",
          }}
        >
          <div className="flex-column flex-center">
            <span>{t("landingPage.suggestion_bug")}</span>
            <span className="font-bold">{t("landingPage.contact_on_x")}</span>
          </div>
        </Link>

        {/* <div className="flex-row hide-phone" style={{ marginLeft: "1rem" }}>
              <a
                className="button is-warn-gradient flex-1"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  fontSize: "0.8rem",
                  height: "40px",
                }}
                href={
                  currency === "EUR"
                    ? process.env.REACT_APP_PAYMENT_LINK_EUR
                    : process.env.REACT_APP_PAYMENT_LINK_USD
                }
                target="_blank"
                rel="noreferrer"
              >
                {t("landingPage.make_a_donation")}
              </a>
              <Dropdown
                buttonStyle={{
                  padding: "0.5rem",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                customMenuStyle={{
                  right: "0px",
                  left: "unset",
                  minWidth: "50px",
                }}
                iconButton={currency === "EUR" ? "euro_symbol" : "attach_money"}
              >
                <button
                  className="dropdown-item dropdown-option"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setCurrency("USD")
                    setDropdownOpen(null)
                  }}
                >
                  <div
                    className="material-symbols-outlined"
                    style={{
                      marginRight: "1rem",
                      marginLeft: "-0.5rem",
                      opacity: 0.5,
                    }}
                  >
                    attach_money
                  </div>
                  <div>USD</div>
                </button>

                <button
                  className="dropdown-item dropdown-option"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => {
                    setCurrency("EUR")
                    setDropdownOpen(null)
                  }}
                >
                  <div
                    className="material-symbols-outlined"
                    style={{
                      marginRight: "1rem",
                      marginLeft: "-0.5rem",
                      opacity: 0.5,
                    }}
                  >
                    euro_symbol
                  </div>
                  <div>EUR</div>
                </button>
              </Dropdown>
            </div> */}
      </div>
      <div
        className={"main-container-editorbar editor-open"}
        onPaste={handlePaste}
      >
        <EditorBar />
        <div
          className={"main-content " + (isEditorOpen ? "is-open" : "is-closed")}
          onDragEnter={onDragEnter}
          onDragLeave={onDragEnd}
          onDragOver={onDragOver}
          onDrop={onFileDrop}
        >
          <div className="LandingPage ImageEditor">
            {isDragHover && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "#f39c1222",
                  border: "0.5rem solid #f39c1288",
                  zIndex: 999,
                }}
                className="flex-column flex-center"
              >
                <div
                  className="white-div bordered-div flex-column flex-center"
                  style={{ padding: "2rem" }}
                >
                  <span
                    className="msymbols material-symbols-outlined"
                    style={{ fontSize: 75, opacity: 0.5 }}
                  >
                    place_item
                  </span>
                  <h1 className="font-bold" style={{ color: "#f39c12" }}>
                    {t("landingPage.drop_screenshot_here")}
                  </h1>
                </div>
              </div>
            )}
            <div className="landing-container editor-container" ref={topRef}>
              <div
                className="flex-column full-width flex-center"
                style={{
                  maxWidth:
                    700 *
                    (selectedVisualFormatConfig.width /
                      selectedVisualFormatConfig.height),
                  width: "100%",
                  maxHeight: "100%",
                  textAlign: "center",
                  aspectRatio:
                    selectedVisualFormatConfig.width +
                    "/" +
                    selectedVisualFormatConfig.height,
                }}
              >
                {/* {isChromium === false && (
                  <p
                    className="warn-bordered-div"
                    style={{ width: "100%", marginBottom: "1rem" }}
                  >
                    <Trans>{t("landingPage.better_on_chrome")}</Trans>
                  </p>
                )} */}

                <div
                  className="full-width flex-center"
                  style={{ display: file ? "flex" : "none", maxHeight: "100%" }}
                >
                  <canvas
                    className="canvas-div"
                    ref={canvasRef}
                    style={{
                      maxWidth: "100%",
                      display: file ? "block" : "none",
                    }}
                  ></canvas>
                </div>

                {!file && (
                  <div
                    className="full-width  flex-center"
                    style={{
                      display: !file ? "block" : "none",
                      maxHeight: "100%",
                    }}
                  >
                    <div
                      className="canvas-div flex-row flex-center flex-1"
                      style={{
                        overflow: "hidden",
                        backgroundSize:
                          selectedVisualFormatConfig.height >=
                          selectedVisualFormatConfig.width
                            ? "auto 100%"
                            : "100% auto",
                        backgroundPosition: "center center",
                        backgroundImage:
                          backgroundType === "gradient"
                            ? `linear-gradient(135deg, ${gradients[selectedGradient].start} 0%, ${gradients[selectedGradient].stop} 100%)`
                            : backgroundType === "wallpaper"
                              ? `url('${backgroundImages?.[wallpaperCategory][selectedBackgroundImage]?.url}')`
                              : backgroundType === "imported"
                                ? `url('${bgFile}')`
                                : "none",

                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        margin: "auto",
                        // height: (screenRef?.current?.offsetWidth || ratioHeight) * (ratioWidth / ratioHeight),
                        position: "relative",
                        aspectRatio: `${ratioWidth}/${ratioHeight}`,
                      }}
                      ref={screenRef}
                    >
                      {visualMode === "image" && !file && (
                        <div
                          onDragEnter={onDragEnter}
                          onDragLeave={onDragEnd}
                          onDragOver={onDragOver}
                          onDrop={onFileDrop}
                          className={
                            "flex-column flex-center primary-div " +
                            (isDragHover ? "primary-gradient-div" : "white-div")
                          }
                          style={{
                            padding: "1rem",
                            margin: selectedVisualFormatConfig.imageMargin,

                            marginTop: selectedVisualFormatConfig.getTopMargin,
                            height:
                              100 -
                              selectedVisualFormatConfig.containerPadding +
                              "%",
                            width:
                              100 -
                              selectedVisualFormatConfig.containerPadding +
                              "%",

                            // minHeight: "100px",
                            // minWidth: "260px",

                            maxHeight: "80%",
                            maxWidth: "80%",

                            position: selectedVisualFormatConfig.imagePosition,
                            top: selectedVisualFormatConfig.imageAbsoluteBottom,

                            borderRadius:
                              selectedVisualFormatConfig.borderRadius,
                            boxShadow: selectedVisualFormatConfig.boxShadow,
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              zIndex: 9999,
                              width: "100%",
                              color: "#ccc",
                              display: showWatermark ? "flex" : "none",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",

                              fontSize:
                                selectedVisualFormatConfig.watermarkSize,
                              ...selectedVisualFormatConfig.watermarkPosition,
                            }}
                          >
                            created&nbsp;with&nbsp;socialscreenshots.com
                          </div>

                          <div
                            className="flex-column flex-center"
                            style={{
                              justifyContent: "stretch",
                            }}
                          >
                            <p className="hide-phone">
                              {t("landingPage.drag_image_here")}
                            </p>
                            <div className="hide-phone flex-row flex-center full-width">
                              <hr
                                className="flex-1"
                                style={{ marginRight: "0.25rem" }}
                              />
                              <b
                                className="half-opacity"
                                style={{ marginBottom: "0.15rem" }}
                              >
                                {t("commons.or")}
                              </b>
                              <hr
                                className="flex-1"
                                style={{ marginLeft: "0.25rem" }}
                              />
                            </div>

                            <label
                              htmlFor="file-upload"
                              className={
                                "hide-phone button flex-row " +
                                (isDragHover
                                  ? "is-bordered"
                                  : "is-warn-gradient")
                              }
                              style={{
                                justifyContent: "flex-start",
                                height: "100%",
                                width: "100%",
                                borderRadius: "21px",
                              }}
                            >
                              <span
                                className="msymbol material-symbols-outlined"
                                style={{
                                  opacity: 0.5,
                                  marginLeft: "-0.25rem",
                                  marginRight: "-0.25rem",
                                }}
                              >
                                image
                              </span>
                              <span style={{ marginLeft: "0.75rem" }}>
                                {t("commons.choose_file")}
                              </span>
                            </label>

                            <button
                              className={
                                "hide-phone download-button button flex-row " +
                                (isDragHover
                                  ? "is-bordered"
                                  : "is-warn-gradient")
                              }
                              style={{
                                justifyContent: "flex-start",
                                height: "100%",
                                width: "100%",
                                marginTop: "1rem",
                                borderRadius: "21px",
                              }}
                              onClick={() => {
                                capture()
                              }}
                            >
                              <span
                                className="msymbol material-symbols-outlined"
                                style={{
                                  opacity: 0.5,
                                  marginLeft: "-0.25rem",
                                  marginRight: "-0.25rem",
                                }}
                              >
                                capture
                              </span>
                              <span style={{ marginLeft: "0.75rem" }}>
                                {t("commons.capture_screenshots")}
                              </span>
                            </button>

                            <label
                              htmlFor="file-upload"
                              className={"flex-column hide-gt-phone"}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                width: "calc(100% - 2rem)",
                                margin: "1rem",
                              }}
                            >
                              <span
                                className="msymbol material-symbols-outlined"
                                style={{
                                  opacity: 0.5,
                                  fontSize: 50,
                                  marginLeft: "-0.25rem",
                                  marginRight: "-0.25rem",
                                }}
                              >
                                image
                              </span>
                              <span>{t("commons.choose_file")}</span>
                            </label>
                          </div>
                        </div>
                      )}

                      {/* {visualMode === "code" && (
                      <div
                        style={{
                          margin: selectedVisualFormatConfig.imageMargin,

                          marginTop: selectedVisualFormatConfig.getTopMargin
                            ? selectedVisualFormatConfig.getTopMargin
                            : selectedVisualFormatConfig.imageMargin,
                          maxHeight: selectedVisualFormatConfig.imageMaxHeight,
                          maxWidth: selectedVisualFormatConfig.imageMaxWidth,

                          minHeight: "40%",
                          minWidth: "60%",

                          borderRadius: selectedVisualFormatConfig.borderRadius,
                          boxShadow: selectedVisualFormatConfig.boxShadow,
                          padding: selectedVisualFormatConfig.insetValue,

                          position: selectedVisualFormatConfig.imagePosition,
                          top: selectedVisualFormatConfig.imageAbsoluteBottom,

                          background: "#282c34",

                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 9999,
                            width: "100%",
                            color: "#ccc",
                            display: showWatermark ? "flex" : "none",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",

                            fontSize: selectedVisualFormatConfig.watermarkSize,
                            ...selectedVisualFormatConfig.watermarkPosition,
                          }}
                        >
                          created&nbsp;with&nbsp;socialscreenshots.com
                        </div>
                        <ReactCodeMirror
                          className="codemirror-container"
                          placeholder={t("landingPage.input_code_placeholder")}
                          height="100%"
                          style={{
                            marginRight: "0.5rem",
                            width: "100%",
                            height: "100%",
                            textAlign: "left",
                            borderRadius:
                              selectedVisualFormatConfig.imageBorderRadius,
                            overflow: "hidden",
                            boxSizing: "border-box",
                          }}
                          value={inputCode}
                          theme="dark"
                          extensions={[
                            javascript({ jsx: true }),
                            EditorView.lineWrapping,
                          ]}
                          onChange={onChange}
                        />
                      </div>
                    )} */}
                    </div>
                  </div>
                )}

                <img
                  ref={bgImage}
                  src={
                    backgroundType === "wallpaper"
                      ? backgroundImages?.[wallpaperCategory][
                          selectedBackgroundImage
                        ]?.url
                      : backgroundType === "imported"
                        ? bgFile
                        : ""
                  }
                  alt=""
                  style={{
                    display: "none",
                  }}
                  onLoad={(e) => {
                    setBgLoaded(true)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-editorbar" style={{ zIndex: 9999 }}>
          <Link
            className="hide-gt-phone social-link"
            to="https://x.com/intent/follow?screen_name=GabFrk"
            target="_blank"
            style={{}}
          >
            <img
              src="/assets/testimonial-pics/pic-1.png"
              alt=""
              style={{
                width: 40,
                height: 40,
                borderRadius: 24,
                marginRight: "0.75rem",
              }}
            />
          </Link>
          {/* <button
            className="download-button button is-bordered"
            style={{ height: "100%", marginRight: "0.75rem" }}
            onClick={() => {
              if (visualMode === "image") {
                setVisualMode("code")
              } else {
                setVisualMode("image")
              }
            }}
          >
            <span
              className="msymbol material-symbols-outlined"
              style={{
                opacity: 0.5,
                marginLeft: "-0.25rem",
                marginRight: "-0.25rem",
              }}
            >
              {visualMode === "code" ? "image" : "code"}
            </span>
            <span className="hide-phone" style={{ marginLeft: "0.75rem" }}>
              {visualMode === "code"
                ? t("landingPage.image_mode")
                : t("landingPage.code_block")}
            </span>
          </button> */}
          <div className="flex-1"></div>
          {visualMode === "image" && (
            <>
              <button
                className="hide-phone download-button button is-bordered"
                style={{ height: "100%", marginLeft: "0.75rem" }}
                onClick={() => {
                  capture()
                }}
              >
                <span
                  className="msymbol material-symbols-outlined"
                  style={{
                    opacity: 0.5,
                    marginLeft: "-0.25rem",
                    marginRight: "-0.25rem",
                  }}
                >
                  capture
                </span>
                <span className="hide-tablet" style={{ marginLeft: "0.75rem" }}>
                  {t("commons.capture_screenshots")}
                </span>
              </button>

              <input
                id="file-upload"
                type="file"
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-upload"
                className="button is-bordered flex-row"
                style={{
                  justifyContent: "flex-start",
                  marginRight: "0.75rem",
                  marginLeft: "0.75rem",
                  height: "100%",
                }}
              >
                <span
                  className="msymbol material-symbols-outlined"
                  style={{
                    opacity: 0.5,
                    marginLeft: "-0.25rem",
                    marginRight: "-0.25rem",
                  }}
                >
                  image
                </span>
                <span className="hide-phone" style={{ marginLeft: "0.75rem" }}>
                  {t("commons.choose_file")}
                </span>
              </label>
            </>
          )}

          <button
            className="download-button button is-warn-gradient"
            style={{
              height: "100%",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            disabled={!file}
            onClick={() => {
              setPreventDoubleSubmit(true)
              onButtonClick()
            }}
          >
            {preventDoubleSubmit ? (
              <>
                <AnimatedLoader smallNoContainer={true} />
                {t("landingPage.generating")}
              </>
            ) : (
              <>
                <span
                  className="msymbol material-symbols-outlined"
                  style={{
                    opacity: 0.5,
                    marginLeft: "-0.25rem",
                    marginRight: "-0.25rem",
                  }}
                >
                  download
                </span>
                <span style={{ marginLeft: "0.75rem" }}>
                  {t("landingPage.download_screenshot")}
                </span>
              </>
            )}
          </button>

          <Dropdown
            buttonClassName="button is-bordered"
            buttonStyle={{
              paddingLeft: "0.5rem",
              paddingRight: "0.75rem",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              fontSize: "0.85rem",
              height: "100%",
              boxSizing: "border-box",
            }}
            customMenuStyle={{
              right: "0px",
              left: "unset",
              top: "-144px",
              minWidth: "50px",
            }}
            style={{ height: "100%" }}
            title={exportTypeName}
          >
            {exportFormats.map((item) => (
              <button
                className="dropdown-item dropdown-option"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setExportTypeName(item.name)
                  setExportType(item.format)
                  setDropdownOpen(null)
                }}
              >
                <div>{item.name}</div>
              </button>
            ))}
          </Dropdown>
        </div>
      </div>

      {/* <Modal
        isShown={modalProductHunt}
        closeModal={() => {
          setModalProductHunt(false)
        }}
      >
        <div className="flex-column flex-center" style={{textAlign:"center"}}>
          <img
            src="/logo512.png"
            alt=""
            style={{ width: "125px", marginBottom: "1rem", marginTop: "1rem" }}
          />
          <h1 style={{ marginBottom: "1rem", fontSize: 24, fontWeight: 800 }}>
            {t("landingPage.launchingToday")}
          </h1>

          <p style={{ marginBottom: "1rem" }}>
            {t("landingPage.launchingTodayInfos")}{" "}
          </p>

          <a
            href="https://www.producthunt.com/posts/socialscreenshots?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-socialscreenshots"
            target="_blank"
            rel="noreferrer"
            style={{
              height: "64px",
            }}
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=458069&theme=light"
              alt="SocialScreenshots - Create&#0032;Stunning&#0032;Visuals&#0032;from&#0032;Your&#0032;Screenshots&#0032;for&#0032;Free&#0033; | Product Hunt"
              style={{
                height: "64px",
              }}
            />
          </a>

          <hr style={{ width: "100%" }} />
          <button
            className="button is-warn-gradient"
            style={{
              marginTop: 1,
              marginBottom: "1rem",
              width: "100%",
              height: "48px"
            }}
            onClick={() => {
              setModalProductHunt(false)
            }}
          >
            <span className="msymbols material-symbols-outlined">
              edit
            </span>
            {t("landingPage.go_to_editor")}
          </button>
        </div>
      </Modal> */}
    </>
  )
}

export default ImageEditor
