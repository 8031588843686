import { Sketch } from "@uiw/react-color"
import { Fragment, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Range } from "react-range"
import { NavLink } from "react-router-dom"

import { Link } from "react-router-dom"
import { ImageEditorContext } from "../../context/ImageEditor"
import { UIContext } from "../../context/UI"
import Dropdown from "../components/Dropdown"
import DropdownLanguage from "../components/DropdownLanguage"

export const EditorBar = () => {
  const { t } = useTranslation()

  const { isDark, toggleDarkMode, setDropdownOpen } = useContext(UIContext)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    file,
    selectedGradient,
    setSelectedGradient,
    selectedBackgroundImage,
    setSelectedBackgroundImage,
    titlePosition,
    setTitlePosition,
    customColor1,
    setCustomColor1,
    customColor2,
    setCustomColor2,
    showColorPicker1,
    setShowColorPicker1,
    showColorPicker2,
    setShowColorPicker2,
    typedTitle,
    gradients,
    selectedVisualFormatConfig,
    setTypedTitle,
    visualFormatConfigs,
    setRatioWidth,
    setRatioHeight,
    setSelectedVisualFormat,

    paddingValue,
    setPaddingValue,
    borderRadiusValue,
    setBorderRadiusValue,
    insetValue,
    setInsetValue,
    insetColor,
    setInsetColor,
    setShowColorPickerInset,
    showColorPickerInset,

    shadowValue,
    setShadowValue,
    shadowColor,
    setShadowColor,
    showColorPickerShadow,
    setShowColorPickerShadow,

    setShowWatermark,
    showWatermark,

    backgroundImages,

    backgroundType,
    setBackgroundType,
    wallpaperCategory,
    setWallpaperCategory,

    setShowColorTextPicker,
    showColorTextPicker,
    textColor,
    setTextColor,

    setBgFile,
    setBgLoaded,

    fileLoaded,

    detectColor,
    importedImageWidth,
    importedImageHeight,
    autoCrop,
    alreadyAutoCropped,

    setInsetColorManuallySet,
    setFile,

    setFileLoaded,
    setAlreadyAutoCropped,
    setOriginalFile,

    setModalCropImage,

    isEditorOpen,
    setIsEditorOpen,
  } = useContext(ImageEditorContext)

  const handleTypedTitle = (event: any) => {
    if (typedTitle.length === 0 && titlePosition === "none") {
      setTitlePosition("top")
    }
    setTypedTitle(event.target.value)
  }

  const openEyeDropper = async () => {
    const eyeDropper = new (window as any).EyeDropper()

    const result = await eyeDropper.open()

    if (result.sRGBHex) {
      setInsetColor(result.sRGBHex)
      setInsetColorManuallySet(true)
    }
  }

  const handleBgChange = (e: any) => {
    setBgLoaded(false)
    setBgFile(URL.createObjectURL(e.target.files[0]))
  }

  const [isDragHover, setIsDragHover] = useState<boolean>(false)

  const onDragOver = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    event.preventDefault()
    setIsDragHover(true)
    setTempBackgroundType("imported")
  }

  const [tempBackgroundType, setTempBackgroundType] = useState<string | null>()

  const onDragEnter = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(true)
    setTempBackgroundType("imported")
  }

  const onDragEnd = (e: any) => {
    let event = e as Event
    event.stopPropagation()
    setIsDragHover(false)
    setTempBackgroundType(null)
  }

  const onFileDrop = (e: any) => {
    let event = e
    event.stopPropagation()
    event.preventDefault()
    setIsDragHover(false)
    setTempBackgroundType(null)

    if (event?.dataTransfer.files) {
      if (event?.dataTransfer.files) {
        setBackgroundType("imported")
        setBgLoaded(false)
        setBgFile(URL.createObjectURL(event?.dataTransfer.files[0]))
      }
    }
  }

  const rgba2hex = (orig: string) => {
    let a: any,
      isPercent: any,
      rgb: any = orig
        .replace(/\s/g, "")
        .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || "").trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : orig

    if (alpha !== "") {
      a = alpha
    } else {
      a = 1
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1)
    hex = hex + a

    return hex
  }

  const handlePaste = async (event: any) => {
    try {
      if (!navigator.clipboard) {
        console.error("Clipboard API not available")
        return
      }

      const clipboardItems = await navigator.clipboard.read()

      for (const clipboardItem of clipboardItems) {
        const imageTypes = clipboardItem.types.find((type) =>
          type.startsWith("image/"),
        )

        if (imageTypes) {
          const blob = await clipboardItem.getType(imageTypes)
          const url = URL.createObjectURL(blob)
          setFileLoaded(false)
          setInsetColorManuallySet(false)
          setAlreadyAutoCropped(false)
          setFile(url)
          setOriginalFile(url)
          break // Assuming we need the first image
        }
      }
    } catch (err) {
      console.error("Failed to read clipboard:", err)
    }
  }

  return (
    <Fragment>
      <div
        className={"editorbar " + (isEditorOpen ? "is-open" : "is-closed")}
        onDragEnter={onDragEnter}
        onDragLeave={onDragEnd}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
        onPaste={handlePaste}
      >
        <div className="flex-row flex-center full-width hide-gt-phone">
          <button
            style={{
              position: "absolute",
              top: "-16px",
              height: "32px",
              width: "100px",
              borderRadius: "16px",
              zIndex: 10,
            }}
            className="button is-bordered"
            onClick={() => setIsEditorOpen(!isEditorOpen)}
          >
            <span
              className="primary-text msymbols material-symbols-outlined"
              style={{
                fontSize: 32,
                transition: "0.3s",
                transform: isEditorOpen ? "rotate(0)" : "rotate(180deg)",
              }}
            >
              keyboard_arrow_down
            </span>
          </button>
        </div>

        {isEditorOpen ? (
          <div className="phone-scroll">
            {isDragHover && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: "#f39c1222",
                  border: "0.5rem solid #f39c1288",
                  zIndex: 999,
                }}
                className="flex-column flex-center"
              >
                <div
                  className="white-div bordered-div flex-column flex-center"
                  style={{ padding: "2rem" }}
                >
                  <span
                    className="msymbols material-symbols-outlined"
                    style={{ fontSize: 75, opacity: 0.5 }}
                  >
                    place_item
                  </span>
                  <h1 className="font-bold" style={{ color: "#f39c12" }}>
                    {t("landingPage.drop_background_here")}
                  </h1>
                </div>
              </div>
            )}

            <div className="bar-container-scroll-container">
              <div className="bar-container-scroll flex-column full-width">
                <NavLink
                  to="/landing"
                  className="navbar-logo hide-phone"
                  style={{ width: "unset" }}
                  onClick={() => {
                    // if (scrollToTop) scrollToTop()
                  }}
                >
                  <ul className={"navbar-nav "}>
                    <span
                      className="link-text flex-row"
                      style={{ alignItems: "center" }}
                    >
                      <img
                        src="/logo512.png"
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "0.75rem",
                        }}
                      />
                      <h1 className="text-center">
                        Social
                        <span className="primary-gradient-red-text font-bold">
                          Screenshots
                        </span>
                      </h1>
                    </span>
                  </ul>
                </NavLink>

                <hr
                  className="full-width hide-phone"
                  style={{ margin: "1rem 0" }}
                />

                <span className="tool-title">
                  {t("landingPage.resolution")}
                </span>
                <Dropdown
                  rightMenu={true}
                  title={
                    selectedVisualFormatConfig ? (
                      <div className="flex-column">
                        <div className="font-bold">
                          {selectedVisualFormatConfig.name}
                        </div>
                        <div
                          style={{
                            fontWeight: "normal",
                            fontSize: "0.9rem",
                            marginTop: "-5px",
                          }}
                        >
                          {selectedVisualFormatConfig.resolution}
                        </div>
                      </div>
                    ) : (
                      "Choisissez une résolution"
                    )
                  }
                  buttonClassName="button is-bordered"
                  buttonStyle={{
                    height: "unset",
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                  }}
                  customMenuStyle={{
                    overflow: "auto",
                  }}
                >
                  {visualFormatConfigs.map((item: any) => (
                    <button
                      className="dropdown-item dropdown-option"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setRatioWidth(item.width)
                        setRatioHeight(item.height)
                        setSelectedVisualFormat(item.resolution)
                        setDropdownOpen(null)
                      }}
                    >
                      <div className="flex-column">
                        <div className="font-bold">{item.name}</div>
                        <div>{item.resolution}</div>
                      </div>
                    </button>
                  ))}
                </Dropdown>

                {/* <div
              className="flex-row full-width"
              style={{ marginTop: "0.75rem" }}
            >
              <div
                className="flex-row flex-1"
                style={{
                  marginRight: "0.325rem",
                }}
              >
                <button
                  onClick={() => {
                    if (!selectedVisualFormatConfig.titlePosition) {
                      setTitlePosition("none")
                    }
                  }}
                  className={
                    "button flex-row flex-center " +
                    (titlePosition === "none"
                      ? "is-primary-gradient"
                      : "is-bordered")
                  }
                  style={{
                    borderRadius: "6px",
                    height: "48px",
                    minWidth: "48px",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/layout-icons/no-text.svg"
                    alt=""
                    className="msymbol material-symbols-outlined"
                    style={{
                      height: 48,
                      opacity: titlePosition === "none" ? 1 : 0.5,
                    }}
                  />
                </button>
              </div>
              <div
                className="flex-row flex-1"
                style={{
                  marginLeft: "0.325rem",
                  marginRight: "0.325rem",
                }}
              >
                <button
                  onClick={() => {
                    if (!selectedVisualFormatConfig.titlePosition) {
                      setTitlePosition("top")
                    }
                  }}
                  className={
                    "button flex-row flex-center " +
                    (titlePosition === "top"
                      ? "is-primary-gradient"
                      : "is-bordered")
                  }
                  style={{
                    borderRadius: "6px",
                    height: "48px",
                    minWidth: "48px",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/layout-icons/text-top.svg"
                    alt=""
                    className="msymbol material-symbols-outlined"
                    style={{
                      height: 48,
                      opacity: titlePosition === "top" ? 1 : 0.5,
                    }}
                  />
                </button>
              </div>
              <div
                className="flex-row flex-1"
                style={{
                  marginLeft: "0.325rem",
                }}
              >
                <button
                  onClick={() => {
                    if (!selectedVisualFormatConfig.titlePosition) {
                      setTitlePosition("bottom")
                    }
                  }}
                  className={
                    "button flex-row flex-center " +
                    (titlePosition === "bottom"
                      ? "is-primary-gradient"
                      : "is-bordered")
                  }
                  style={{
                    borderRadius: "6px",
                    height: "48px",
                    minWidth: "48px",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/layout-icons/text-bottom.svg"
                    alt=""
                    className="msymbol material-symbols-outlined"
                    style={{
                      height: 48,
                      opacity: titlePosition === "bottom" ? 1 : 0.5,
                    }}
                  />
                </button>
              </div>
            </div> */}

                {/* {titlePosition !== "none" && (
              <>
                <span className="tool-title" style={{ marginTop: "0.75rem" }}>
                  {t("landingPage.text")}
                </span>

                <div className="flex-row full-width">
                  <div
                    className="button is-bordered"
                    style={{
                      borderRadius: "6px",
                      color: textColor,
                      background: "#ccc",
                      height: "28px",
                      width: "28px",
                      minHeight: "28px",
                      minWidth: "28px",
                      padding: 0,
                      marginRight: "0.5rem",
                    }}
                    onClick={() => setShowColorTextPicker(true)}
                  >
                    A
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowColorTextPicker(false)
                      }}
                      style={{
                        display: showColorTextPicker ? "block" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9,
                      }}
                    ></div>
                    <div
                      style={{
                        display: showColorTextPicker ? "block" : "none",
                        position: "absolute",
                        left: 28,
                        zIndex: 10,
                      }}
                    >
                      <Sketch
                        style={{}}
                        color={textColor}
                        onChange={(color) => {
                          localStorage.setItem("textColor", color.hex)
                          setTextColor(color.hex)
                        }}
                      />
                    </div>
                  </div>

                  <div className="filed flex-1">
                    <div className="control">
                      <textarea
                        rows={2}
                        style={{ resize: "none", height: "unset" }}
                        className={`full-width input`}
                        placeholder={t("landingPage.input_catchline")}
                        value={typedTitle}
                        onChange={handleTypedTitle}
                      />
                    </div>
                  </div>
                </div>
              </>
            )} */}

                <hr
                  className="full-width hide-gt-phone"
                  style={{ margin: "1rem 0" }}
                />

                <div className="flex-row hide-gt-phone">
                  <button
                    className="flex-1 download-button button is-bordered"
                    style={{
                      marginRight: "0.5rem",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      setModalCropImage(true)
                    }}
                    disabled={!file}
                  >
                    <span
                      className="msymbol material-symbols-outlined"
                      style={{
                        opacity: 0.5,
                        marginLeft: "-0.25rem",
                        marginRight: "-0.25rem",
                      }}
                    >
                      crop
                    </span>
                    <span style={{ marginLeft: "0.75rem" }}>
                      {t("landingPage.crop")}
                    </span>
                  </button>

                  <button
                    className="flex-1 download-button button is-bordered"
                    style={{
                      marginLeft: "0.5rem",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      autoCrop()
                    }}
                    disabled={!file}
                  >
                    {!alreadyAutoCropped ? (
                      <>
                        <span
                          className="msymbol material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            marginLeft: "-0.25rem",
                            marginRight: "-0.25rem",
                          }}
                        >
                          photo_size_select_small
                        </span>
                        <span style={{ marginLeft: "0.75rem" }}>
                          {t("landingPage.auto_crop")}
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="msymbol material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            marginLeft: "-0.25rem",
                            marginRight: "-0.25rem",
                          }}
                        >
                          undo
                        </span>
                        <span style={{ marginLeft: "0.75rem" }}>
                          {t("landingPage.back_to_original")}
                        </span>
                      </>
                    )}
                  </button>
                </div>

                <hr
                  className="full-width"
                  style={{ margin: "1rem 0 0.75rem" }}
                />

                <span className="tool-title">
                  {t("landingPage.background")}
                </span>

                <div
                  className="flex-row"
                  style={{ marginBottom: "0.75rem", marginTop: "0.5rem" }}
                >
                  <button
                    onClick={() => {
                      localStorage.setItem("backgroundType", "wallpaper")
                      setBgLoaded(false)
                      setBackgroundType("wallpaper")
                    }}
                    style={{ fontSize: "0.8rem", flex: 1, textAlign: "center" }}
                    className={
                      "button text-ellipsis wallpaper-category " +
                      (!tempBackgroundType && backgroundType === "wallpaper"
                        ? "selected-category"
                        : "")
                    }
                  >
                    {t("landingPage.wallpapers")}
                  </button>

                  <button
                    onClick={() => {
                      localStorage.setItem("backgroundType", "gradient")
                      setBgLoaded(false)
                      setBackgroundType("gradient")
                    }}
                    style={{ fontSize: "0.8rem", flex: 1, textAlign: "center" }}
                    className={
                      "button text-ellipsis wallpaper-category " +
                      (!tempBackgroundType && backgroundType === "gradient"
                        ? "selected-category"
                        : "")
                    }
                  >
                    {t("landingPage.gradients")}
                  </button>

                  {/* <button
                onClick={() => {
                  localStorage.setItem("backgroundType", "color")
                  setBgLoaded(false)
                  setBackgroundType("color")
                }}
                style={{ fontSize: "0.8rem", flex: 1, textAlign: "center" }}
                className={
                  "button text-ellipsis wallpaper-category " +
                  (!tempBackgroundType && backgroundType === "color"
                    ? "selected-category"
                    : "")
                }
              >
                {t("landingPage.color")}
              </button> */}

                  <button
                    onClick={() => {
                      localStorage.setItem("backgroundType", "imported")
                      setBgLoaded(false)
                      setBackgroundType("imported")
                    }}
                    style={{ fontSize: "0.8rem", flex: 1, textAlign: "center" }}
                    className={
                      "button text-ellipsis wallpaper-category " +
                      (tempBackgroundType === "imported" ||
                      backgroundType === "imported"
                        ? "selected-category"
                        : "")
                    }
                  >
                    {t("commons.import")}
                  </button>
                </div>

                {!tempBackgroundType && backgroundType === "gradient" && (
                  <>
                    <hr style={{ margin: "0rem 0 0.75rem" }} />
                    <div
                      onClick={() => {
                        localStorage.setItem("selectedGradient", (0).toString())
                        setSelectedGradient(0)
                      }}
                      className="flex-row"
                    >
                      <div
                        className="flex-row flex-1"
                        style={{
                          marginRight: "0.5rem",
                        }}
                        onClick={() => setShowColorPicker1(true)}
                      >
                        <div
                          className="button is-bordered"
                          style={{
                            background: customColor1,
                            height: "28px",
                            width: "28px",
                            minHeight: "28px",
                            minWidth: "28px",
                            padding: 0,
                            borderRadius: "6px 0 0 6px",
                          }}
                        >
                          <div
                            onClick={(e) => {
                              e.stopPropagation()
                              setShowColorPicker1(false)
                            }}
                            style={{
                              display: showColorPicker1 ? "block" : "none",
                              position: "fixed",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              zIndex: 9,
                            }}
                          ></div>
                          <div
                            style={{
                              display: showColorPicker1 ? "block" : "none",
                              position: "absolute",
                              top: "-293.5px",
                              left: 0,
                              zIndex: 10,
                            }}
                          >
                            <Sketch
                              style={{}}
                              color={customColor1}
                              onChange={(color) => {
                                localStorage.setItem("customColor1", color.hex)
                                setCustomColor1(color.hex)
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="flex-row bordered-div flex-1"
                          style={{
                            borderRadius: "0 6px 6px 0",
                            height: "28px",
                            minHeight: "28px",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: "0 0.5rem",
                            borderLeft: "none",
                          }}
                        >
                          {customColor1}
                        </div>
                      </div>
                      <div
                        className="flex-row flex-1"
                        style={{
                          marginLeft: "0.5rem",
                        }}
                        onClick={() => setShowColorPicker2(true)}
                      >
                        <div
                          className="button is-bordered"
                          style={{
                            background: customColor2,
                            height: "28px",
                            width: "28px",
                            minHeight: "28px",
                            minWidth: "28px",
                            padding: 0,
                            borderRadius: "6px 0 0 6px",
                          }}
                        >
                          <div
                            onClick={(e) => {
                              e.stopPropagation()
                              setShowColorPicker2(false)
                            }}
                            style={{
                              display: showColorPicker2 ? "block" : "none",
                              position: "fixed",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              zIndex: 9,
                            }}
                          ></div>
                          <div
                            style={{
                              display: showColorPicker2 ? "block" : "none",
                              position: "absolute",
                              top: "-293.5px",
                              left: -50,
                              zIndex: 10,
                            }}
                          >
                            <Sketch
                              color={customColor2}
                              onChange={(color) => {
                                localStorage.setItem("customColor2", color.hex)
                                setCustomColor2(color.hex)
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="flex-row bordered-div flex-1"
                          style={{
                            borderRadius: "0 6px 6px 0",
                            height: "28px",
                            minHeight: "28px",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            padding: "0 0.5rem",
                            borderLeft: "none",
                          }}
                        >
                          {customColor2}
                        </div>
                      </div>
                    </div>

                    <hr style={{ margin: "0.75rem 0" }} />

                    <div
                      className="editor-background-grid full-width"
                      style={{
                        display: "grid",
                        gap: "5px",
                        gridTemplateColumns:
                          "repeat(auto-fit, calc(20% - (4px)))",
                        margin: "-3px",
                        width: "calc(100% + 6px)",
                      }}
                    >
                      {gradients.map((item: any, index: number) => {
                        if (index > 0) {
                          return (
                            <div
                              onClick={() => {
                                localStorage.setItem(
                                  "selectedGradient",
                                  index.toString(),
                                )
                                setSelectedGradient(index)
                              }}
                              className={
                                "gradient-button " +
                                (selectedGradient === index && "selected")
                              }
                            >
                              <div
                                style={{
                                  margin: "2px",
                                  borderRadius: "6px",
                                  minHeight: "38px",
                                  background: `linear-gradient(135deg, ${item.start} 0%, ${item.stop} 100%)`,
                                }}
                              ></div>
                            </div>
                          )
                        }
                      })}
                    </div>
                  </>
                )}

                {backgroundType === "wallpaper" && (
                  <div className="flex-row" style={{ marginBottom: "0.75rem" }}>
                    <button
                      onClick={() => {
                        localStorage.setItem("wallpaperCategory", "macos")
                        setBgLoaded(false)
                        setWallpaperCategory("macos")
                      }}
                      style={{ fontSize: "0.8rem", marginRight: "0.5rem" }}
                      className={
                        "button " +
                        (!tempBackgroundType && wallpaperCategory === "macos"
                          ? "is-primary-gradient"
                          : "is-bordered")
                      }
                    >
                      Mac OS
                    </button>
                    <button
                      onClick={() => {
                        localStorage.setItem("wallpaperCategory", "raycast")
                        setBgLoaded(false)
                        setWallpaperCategory("raycast")
                      }}
                      style={{ fontSize: "0.8rem", marginRight: "0.5rem" }}
                      className={
                        "button " +
                        (!tempBackgroundType && wallpaperCategory === "raycast"
                          ? "is-primary-gradient"
                          : "is-bordered")
                      }
                    >
                      Raycast
                    </button>
                  </div>
                )}

                {!tempBackgroundType &&
                  backgroundType === "wallpaper" &&
                  wallpaperCategory === "macos" && (
                    <div
                      className="editor-background-grid full-width"
                      style={{
                        display: "grid",
                        gap: "5px",
                        gridTemplateColumns:
                          "repeat(auto-fit, calc(20% - (4px)))",
                        margin: "-3px",
                        width: "calc(100% + 6px)",
                      }}
                    >
                      {backgroundImages?.macos.map(
                        (item: any, index: number) => (
                          <div
                            className={
                              "gradient-button " +
                              (selectedBackgroundImage === index && "selected")
                            }
                            onClick={() => {
                              localStorage.setItem(
                                "selectedBackgroundImage",
                                index.toString(),
                              )
                              setBgLoaded(false)
                              setSelectedBackgroundImage(index)
                            }}
                          >
                            <div
                              style={{
                                margin: "2px",
                                minHeight: "38px",
                                borderRadius: "6px",

                                backgroundImage: `url(${item.url})`,
                                backgroundSize: "100% 100%",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          </div>
                        ),
                      )}
                    </div>
                  )}

                {!tempBackgroundType &&
                  backgroundType === "wallpaper" &&
                  wallpaperCategory === "raycast" && (
                    <div
                      className="editor-background-grid full-width"
                      style={{
                        display: "grid",
                        gap: "5px",
                        gridTemplateColumns:
                          "repeat(auto-fit, calc(20% - (4px)))",
                        margin: "-3px",
                        width: "calc(100% + 6px)",
                      }}
                    >
                      {backgroundImages?.raycast.map(
                        (item: any, index: number) => (
                          <div
                            className={
                              "gradient-button " +
                              (selectedBackgroundImage === index && "selected")
                            }
                            onClick={() => {
                              localStorage.setItem(
                                "selectedBackgroundImage",
                                index.toString(),
                              )
                              setBgLoaded(false)
                              setSelectedBackgroundImage(index)
                            }}
                          >
                            <div
                              style={{
                                margin: "2px",
                                minHeight: "38px",
                                borderRadius: "6px",

                                backgroundImage: `url(${item.url})`,
                                backgroundSize: "100% 100%",
                                backgroundPosition: "center",
                              }}
                            ></div>
                          </div>
                        ),
                      )}
                    </div>
                  )}

                {(tempBackgroundType === "imported" ||
                  backgroundType === "imported") && (
                  <>
                    <input
                      id="bg-file-upload"
                      type="file"
                      onChange={handleBgChange}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="bg-file-upload"
                      onDragEnter={onDragEnter}
                      onDragLeave={onDragEnd}
                      onDragOver={onDragOver}
                      onDrop={onFileDrop}
                      className={
                        "flex-column file-selector flex-center " +
                        (isDragHover ? "file-hover" : "file-not-hover")
                      }
                      style={{
                        minHeight: 125,
                        borderRadius: 16,
                      }}
                    >
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: "cetner",
                        }}
                      >
                        <span
                          className="msymbol material-symbols-outlined"
                          style={{
                            opacity: 0.5,
                            marginRight: "0.5rem",
                          }}
                        >
                          image
                        </span>

                        <span>{t("commons.choose_file")}</span>
                      </div>
                    </label>
                  </>
                )}

                <hr className="full-width" style={{ margin: "0.75rem 0" }} />

                <span className="tool-title" style={{}}>
                  {t("landingPage.padding")}
                </span>

                <div
                  className="full-width flex-row flex-center"
                  style={{
                    height: 28,
                  }}
                >
                  <Range
                    step={1}
                    min={0}
                    max={75}
                    values={[paddingValue]}
                    onChange={([values]) => {
                      localStorage.setItem("paddingVal", values.toFixed())
                      setPaddingValue(values)
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "100%",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="range-value flex-row flex-center"
                        {...props}
                        style={{
                          ...props.style,
                        }}
                      >
                        {paddingValue.toFixed()}
                      </div>
                    )}
                  />
                </div>

                <span className="tool-title" style={{ marginTop: "0.75rem" }}>
                  {t("landingPage.border_radius")}
                </span>

                <div
                  className="full-width flex-row flex-center"
                  style={{
                    height: 28,
                  }}
                >
                  <Range
                    step={1}
                    min={0}
                    max={100}
                    values={[borderRadiusValue]}
                    onChange={([values]) => {
                      localStorage.setItem("borderRadiusVal", values.toFixed())
                      setBorderRadiusValue(values)
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "100%",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="range-value flex-row flex-center"
                        {...props}
                        style={{
                          ...props.style,
                        }}
                      >
                        {borderRadiusValue.toFixed()}
                      </div>
                    )}
                  />
                </div>

                <span className="tool-title" style={{ marginTop: "0.75rem" }}>
                  {t("landingPage.inset")}
                </span>

                <div
                  className="flex-row"
                  style={{
                    marginBottom: "0.5rem",
                  }}
                >
                  <div
                    className="button is-bordered"
                    style={{
                      background: insetColor,
                      height: "28px",
                      width: "28px",
                      minHeight: "28px",
                      minWidth: "28px",
                      padding: 0,
                      borderRadius: "6px 0 0 6px",
                    }}
                    onClick={() => setShowColorPickerInset(true)}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowColorPickerInset(false)
                      }}
                      style={{
                        display: showColorPickerInset ? "block" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9,
                      }}
                    ></div>
                    <div
                      style={{
                        display: showColorPickerInset ? "block" : "none",
                        position: "absolute",
                        top: "-293.5px",
                        left: 0,
                        zIndex: 10,
                      }}
                    >
                      <Sketch
                        style={{}}
                        color={insetColor}
                        onChange={(color) => {
                          localStorage.setItem(
                            "insetColor",
                            `rgba(${color.rgba.r},${color.rgba.g},${color.rgba.b},${color.rgba.a.toFixed(2)})`,
                          )
                          setInsetColor(
                            `rgba(${color.rgba.r},${color.rgba.g},${color.rgba.b},${color.rgba.a.toFixed(2)})`,
                          )
                          setInsetColorManuallySet(true)
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="flex-row bordered-div flex-1"
                    style={{
                      borderRadius: "0",
                      height: "28px",
                      minHeight: "28px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "0 0.5rem",
                      borderLeft: "none",
                    }}
                    onClick={() => setShowColorPickerInset(true)}
                  >
                    {insetColor}
                  </div>

                  <button
                    className="button is-bordered"
                    style={{
                      borderRadius: "0 6px 6px 0",
                      height: "28px",
                      width: "28px",
                      minHeight: "28px",
                      minWidth: "28px",
                      padding: 0,
                      borderLeft: "none",
                    }}
                    onClick={() => openEyeDropper()}
                  >
                    <span
                      className="msymbol material-symbols-outlined"
                      style={{ fontSize: 18 }}
                    >
                      colorize
                    </span>
                  </button>

                  <button
                    className="button is-bordered"
                    style={{
                      borderRadius: "6px",
                      height: "28px",
                      minHeight: "28px",
                      marginLeft: "0.5rem",
                      padding: "0 0.5rem 0 0.25rem",
                    }}
                    onClick={() =>
                      detectColor(importedImageWidth, importedImageHeight)
                    }
                  >
                    <span
                      className="msymbol material-symbols-outlined"
                      style={{ fontSize: 18, marginRight: "0.25rem" }}
                    >
                      palette
                    </span>
                    <p style={{ fontWeight: 400, fontSize: "0.8rem" }}>Auto</p>
                  </button>
                </div>

                <div
                  className="full-width flex-row flex-center"
                  style={{ height: 28 }}
                >
                  <Range
                    step={1}
                    min={0}
                    max={100}
                    values={[insetValue]}
                    onChange={([values]) => {
                      localStorage.setItem("insetVal", values.toFixed())
                      setInsetValue(values)
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "100%",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="range-value flex-row flex-center"
                        {...props}
                        style={{
                          ...props.style,
                        }}
                      >
                        {insetValue.toFixed()}
                      </div>
                    )}
                  />

                  <button
                    className="button is-bordered"
                    style={{
                      borderRadius: "6px",
                      height: "28px",
                      minHeight: "28px",
                      marginLeft: "0.5rem",
                      padding: "0 0.5rem 0 0.25rem",
                    }}
                    onClick={() => autoCrop()}
                  >
                    {!alreadyAutoCropped ? (
                      <>
                        <span
                          className="msymbol material-symbols-outlined"
                          style={{ fontSize: 18, marginRight: "0.25rem" }}
                        >
                          photo_size_select_small
                        </span>
                        <p style={{ fontWeight: 400, fontSize: "0.8rem" }}>
                          Auto
                        </p>{" "}
                      </>
                    ) : (
                      <>
                        <span
                          className="msymbol material-symbols-outlined"
                          style={{ fontSize: 18, marginRight: "0.25rem" }}
                        >
                          undo
                        </span>
                        <p style={{ fontWeight: 400, fontSize: "0.8rem" }}>
                          Back
                        </p>
                      </>
                    )}
                  </button>
                </div>

                <span className="tool-title" style={{ marginTop: "0.75rem" }}>
                  {t("landingPage.shadow")}
                </span>

                <div
                  className="flex-row"
                  style={{
                    marginBottom: "0.5rem",
                  }}
                >
                  <div
                    className="button is-bordered"
                    style={{
                      background: shadowColor,
                      height: "28px",
                      width: "28px",
                      minHeight: "28px",
                      minWidth: "28px",
                      padding: 0,
                      borderRadius: "6px 0 0 6px",
                    }}
                    onClick={() => setShowColorPickerShadow(true)}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setShowColorPickerShadow(false)
                      }}
                      style={{
                        display: showColorPickerShadow ? "block" : "none",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9,
                      }}
                    ></div>
                    <div
                      style={{
                        display: showColorPickerShadow ? "block" : "none",
                        position: "absolute",
                        top: "-293.5px",
                        left: 0,
                        zIndex: 10,
                      }}
                    >
                      <Sketch
                        style={{}}
                        color={shadowColor}
                        onChange={(color) => {
                          localStorage.setItem(
                            "shadowColor",
                            "#" +
                              rgba2hex(
                                `rgba(${color.rgba.r},${color.rgba.g},${color.rgba.b},${color.rgba.a.toFixed(2)})`,
                              ),
                          )
                          setShadowColor(
                            "#" +
                              rgba2hex(
                                `rgba(${color.rgba.r},${color.rgba.g},${color.rgba.b},${color.rgba.a.toFixed(2)})`,
                              ),
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="flex-row bordered-div flex-1"
                    style={{
                      borderRadius: "0 6px 6px 0",
                      height: "28px",
                      minHeight: "28px",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "0 0.5rem",
                      borderLeft: "none",
                    }}
                    onClick={() => setShowColorPickerShadow(true)}
                  >
                    {shadowColor}
                  </div>
                </div>

                <div
                  className="full-width flex-row flex-center"
                  style={{ height: 28 }}
                >
                  <Range
                    step={1}
                    min={0}
                    max={150}
                    values={[shadowValue]}
                    onChange={([values]) => {
                      localStorage.setItem("shadowVal", values.toFixed())
                      setShadowValue(values)
                    }}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "0.5rem",
                          borderRadius: "0.25rem",
                          width: "100%",
                          backgroundColor: "#ccc",
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="range-value flex-row flex-center"
                        {...props}
                        style={{
                          ...props.style,
                        }}
                      >
                        {shadowValue.toFixed()}
                      </div>
                    )}
                  />
                </div>

                {/* <hr className="full-width" style={{ margin: "0.75rem 0 " }} />

            <span className="tool-title">{t("landingPage.watermark")}</span>

            <div
              className="flex-row"
              style={{ opacity: userAccountTier !== 1 ? 0.25 : 1 }}
            >
              <div className="checkbox-wrapper">
                <input
                  className="tgl tgl-light"
                  id="checkbox-watermark"
                  type="checkbox"
                  disabled={userAccountTier !== 1}
                  checked={showWatermark}
                  onChange={() => {
                    if (userAccountTier === 1) {
                      setShowWatermark(!showWatermark)
                    }
                  }}
                />
                <label className="tgl-btn" htmlFor="checkbox-watermark" />
              </div>

              <span
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  if (userAccountTier === 1) {
                    setShowWatermark(!showWatermark)
                  }
                }}
              >
                {t("landingPage.show_watermark")}
              </span>
            </div> */}
              </div>
            </div>

            {/* BOTTOM */}
            <div
              className="bar-container flex-column full-width"
              style={{ paddingTop: 0 }}
            >
              {/* <p
            style={{ opacity: 0.85, textAlign: "center", fontSize: "0.85rem" }}
          >
            <Trans
              i18nKey={"landingPage.donation_infos"}
              components={[
                <a href="https://x.com/intent/follow?screen_name=GabFrk" target="_blank" rel="noreferrer">
                  Gabriel Franck
                </a>,
              ]}
            ></Trans>
          </p> */}
              <hr className="full-width" style={{ margin: "0rem 0 1rem" }} />

              <div className="flex-row">
                <a
                  className="champo-link"
                  href="https://champo.ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  {isDark ? (
                    <img src="/dark_champo_ai_watermark.png" alt="" />
                  ) : (
                    <img src="/light_champo_ai_watermark.png" alt="" />
                  )}
                </a>
              </div>
              <div className="flex-row">
                <DropdownLanguage className="full-width" />

                <button
                  className="button is-bordered"
                  style={{ margin: 0, marginLeft: "0.5rem", padding: "0.5rem" }}
                  onClick={() => toggleDarkMode()}
                >
                  <span className="msymbol material-symbols-outlined">
                    {!isDark ? "dark_mode" : "light_mode"}
                  </span>
                </button>
              </div>

              <hr
                className="full-width hide-gt-phone"
                style={{ margin: "1rem 0" }}
              />
              <div className="flex-row full-width">
                <NavLink
                  to="/landing"
                  className="navbar-logo hide-gt-phone"
                  style={{ width: "unset" }}
                  onClick={() => {
                    // if (scrollToTop) scrollToTop()
                  }}
                >
                  <ul className={"navbar-nav "}>
                    <span
                      className="link-text flex-row"
                      style={{ alignItems: "center" }}
                    >
                      <img
                        src="/logo512.png"
                        alt=""
                        style={{
                          width: 40,
                          height: 40,
                          marginRight: "0.75rem",
                        }}
                      />
                      <h1 className="text-center">
                        Social
                        <span className="primary-gradient-red-text font-bold">
                          Screenshots
                        </span>
                      </h1>
                    </span>
                  </ul>
                </NavLink>
              </div>

              <div
                className="flex-row hide-gt-phone"
                style={{ marginTop: "1rem" }}
              >
                <Link
                  className="flex-row social-link flex-1"
                  to="https://x.com/intent/follow?screen_name=GabFrk"
                  target="_blank"
                  style={{
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <img
                    src="/assets/testimonial-pics/pic-1.png"
                    alt=""
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 24,
                      marginRight: "0.75rem",
                    }}
                  />
                  <div className="flex-column">
                    <b
                      style={{ fontSize: "0.7rem", fontStyle: "italic" }}
                      className="warn-text"
                    >
                      made with ❤️ by
                    </b>

                    <b style={{ fontSize: "0.75rem" }}>Gab Franck</b>
                  </div>
                </Link>

                <Link
                  to="https://x.com/intent/follow?screen_name=GabFrk"
                  target="_blank"
                  className="warn-bordered-link flex-row flex-center"
                  style={{
                    height: "40px",
                    padding: "0 0.5rem",
                    fontSize: "0.65rem",
                    lineHeight: 1.15,
                    marginLeft: "1rem",
                    textAlign: "center",
                  }}
                >
                  <div className="flex-column flex-center">
                    <span>{t("landingPage.suggestion_bug")}</span>
                    <span className="font-bold">
                      {t("landingPage.contact_on_x")}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="flex-row flex-center"
              style={{ height: "48px", marginTop: "16px", padding: "0 1rem", textAlign:"center" }}
              onClick={() => setIsEditorOpen(true)}
            >
              <span className="tool-title" style={{marginTop: "0.25rem"}}>{t("landingPage.show_toolbar")}</span>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default EditorBar
