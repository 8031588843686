import { useTranslation } from "react-i18next"
import { Link, NavLink } from "react-router-dom"

type Props = {
  scrollToTop?: () => void
  scrollToFeatures?: () => void
  scrollToPricing?: () => void
  scrollToFaq?: () => void
  hideLinks?: boolean
}

export const TopBar = ({
  scrollToTop,
  scrollToFeatures,
  scrollToPricing,
  scrollToFaq,
  hideLinks = false,
}: Props) => {
  const { t } = useTranslation()

  return (
    <div className="top-bar">
      <NavLink
        to="/landing"
        className="navbar-logo"
        style={{ width: "unset" }}
        onClick={() => {
          if (scrollToTop) scrollToTop()
        }}
      >
        <ul className={"navbar-nav "}>
          <span className="link-text flex-row flex-center">
            <img
              src="/logo512.png"
              alt=""
              style={{ width: 40, height: 40, marginRight: "0.75rem" }}
            />

            <h1 className="text-center">
              Social
              <span className="primary-gradient-red-text font-bold">
                Screenshots
              </span>
            </h1>
            {/* <div className="soon-div" style={{ fontSize: 16, marginRight: 0 }}>
              beta
            </div> */}
          </span>
        </ul>
      </NavLink>

      {!hideLinks && (
        <div className="nav-container">
          <div>
            <button
              className="hide-tablet TextLink"
              onClick={() => {
                if (scrollToFeatures) scrollToFeatures()
              }}
            >
              {t("landingPage.features")}
            </button>
            {/* <button
              className="hide-tablet TextLink"
              onClick={() => {
                if (scrollToPricing) scrollToPricing()
              }}
            >
              {t("landingPage.pricing")}
            </button> */}
            <button
              className="hide-tablet TextLink"
              onClick={() => {
                if (scrollToFaq) scrollToFaq()
              }}
            >
              FAQ
            </button>

            <Link
              className="button is-warn-gradient hide-phone"
              to="/editor"
              style={{ marginLeft: "1rem" }}
            >
              {t("landingPage.go_to_editor")}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
