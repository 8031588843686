import "react-toastify/dist/ReactToastify.css"
import "./styles/App.scss"
import "./translations"

import { useTranslation } from "react-i18next"
import { useCallback, useEffect } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

// Pages
import ImageEditor from "./ui/pages/ImageEditor"
import LandingPage from "./ui/pages/LandingPage"
import ScreenSuccess from "./ui/pages/ScreenSuccess"

export const App = (props: any) => {
  const { i18n } = useTranslation()

  const setLang = async (lang: string) => {
    await i18n.changeLanguage(lang)
  }

  useEffect(() => {
    let currentLang = localStorage.getItem("currentLang")
    if (currentLang) setLang(currentLang)
  }, [])

  const getAvailablesRoutes = useCallback(() => {
    return (
      <Routes>
        <Route path="/" element={<Navigate replace to="/editor" />} />
        <Route
          path="/landing"
          element={landingContainer(LandingPage)({ ...props })}
        />
        <Route
          path="/editor"
          element={landingContainer(ImageEditor)({ ...props })}
        />
        <Route path="/screen-success" element={<ScreenSuccess />} />

        <Route path="*" element={<Navigate replace to="/editor" />} />
      </Routes>
    )
  }, [props])

  return <BrowserRouter>{getAvailablesRoutes()}</BrowserRouter>
}

const landingContainer = (Component: any) => (props: any) => {
  return (
    <>
      <Component {...props} />
    </>
  )
}

export default App
